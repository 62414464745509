<div class="modal-header">
    <h4 class="modal-title">{{ modalTitle  }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p> {{ 'DELETE_CONFIRMATION' | lang: 'en' }} {{ confirmationMessage }} {{ contentMessage }}</p>
  </div>
  <div class="modal-footer">
  	<button type="button" class="btn btn-danger" (click)="modal.close(true)">{{ 'OK' | lang: 'en' }}</button>
    <button type="button" ngbAutofocus class="btn btn-light" (click)="modal.close(false)">{{ 'CANCEL' | lang: 'en' }}</button>
  </div>
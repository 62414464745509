import { Poi } from './../../model/trace/poi';
import { TraceDay } from './../../model/trace/traceDay';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstService } from "../const.service";
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Position } from '../../model/trace/position';
import {UserService} from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class TraceService {

  
  constructor(
    private http: HttpClient, private constService: ConstService, private userService : UserService) { }

  getTimetable(): Observable<TraceDay[]>|undefined {
    const uid = this.userService.getUsername();
    return this.http.get<TraceDay[]>(`${this.constService.baseUrl}/${uid}/timetable`).pipe(catchError(error => {
      if (error.error instanceof ErrorEvent) {
        console.log(`Error: ${error.error.message}`);
      } else {
        console.log(`Error: ${error.message}`);
      }
      return of<TraceDay[]>([]);
    }))
    .pipe(map(items => items.map(item => new TraceDay(item))))
  }

  getPositions(granularity : number, year: number, month: number, day: number): Observable<Position[]>|undefined{
    const uid = this.userService.getUsername();
    return this.http.get<Position[]>(`${this.constService.baseUrl}/${uid}/${granularity}/${year}/${month}/${day}`)
    .pipe(catchError(error => {
      if (error.error instanceof ErrorEvent) {
        console.log(`Error: ${error.error.message}`);
      } else {
        console.log(`Error: ${error.message}`);
      }
      return of<Position[]>([]);
    }))
    .pipe(map(items => items.map(item => new Position(item))))
  }

  getObfPositions(granularity : number, year: number, month: number, day: number, epsilon : number): Observable<Position[]>|undefined{
    const uid = this.userService.getUsername();
    return this.http.get<Position[]>(`${this.constService.baseUrl}/${uid}/obfsct/${epsilon}/${granularity}/${year}/${month}/${day}`)
    .pipe(catchError(error => {
      if (error.error instanceof ErrorEvent) {
        console.log(`Error: ${error.error.message}`);
      } else {
        console.log(`Error: ${error.message}`);
      }
      return of<Position[]>([]);
    }))
    .pipe(map(items => items.map(item => new Position(item))))
  }

  getPoi(granularity : number,  poi_id: number) : Observable<Poi>|undefined{
    if(!granularity)
      granularity = 1800;
    const uid = this.userService.getUsername();
    return this.http.get<Poi>(`${this.constService.baseUrl}/${uid}/element/${granularity}/${poi_id}`)
    .pipe(catchError(error => {
      if (error.error instanceof ErrorEvent) {
        console.log(`Error: ${error.error.message}`);
      } else {
        console.log(`Error: ${error.message}`);
      }
      return of<Poi>();
    }))
  }
}

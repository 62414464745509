<div *ngIf="poi; then thenBlock else elseBlock"></div>

<ng-template #thenBlock>
    <div class="container-fluid">
        <div *ngIf="image_found">
            <div class="col-12">
                <img [src]="image_url" alt="photo near the POI" style="width: 100%;height: auto;">
            </div>
            <hr>
        </div>
        <div *ngIf="poi.elements.length && selectedElement; then elementBlock else noElementBlock"></div>
        <ng-template #elementBlock>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">POI</label>
                </div>
                <select class="custom-select" id="inputGroupSelect01" (change)="selectElement($event.target.value)">
            <ng-container *ngFor="let elem of poi.elements; let i=index">
            <option [value]="i">{{i+1}} - {{elem.element.address}} - confidence : {{elem.confidence}}</option>
            </ng-container>
            </select>
            </div>
            <div class="row">
                <div class="col-12 col-lg-2"> <b>Address:</b> </div>
                <div class="col" style="text-align: right;"> <br>{{selectedElement.address}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-lg-2"> <b>Names:</b> </div>
                <div class="col" style="text-align: right;">
                    <ng-container *ngFor="let tag of selectedElement.names">
                        {{(tag.label).concat(" ")}}
                    </ng-container>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-lg-2"> <b>Tags:</b> </div>
                <div class="col" style="text-align: right;">
                    <ng-container *ngFor="let tag of selectedElement.tags">
                        {{(tag.label).concat(" ")}}
                    </ng-container>
                </div>
            </div>
            <hr>
        </ng-template>
        <ng-template #noElementBlock>
            <p style="text-align: center;">Loading POI data.</p>
        </ng-template>
        <!-- | date:'H:mm:ss':'UTC' -->
        <div class="row">
            <div class="col-12 col-lg-2">
                <b>first visit</b>
            </div>
            <div class="col" style="text-align: right;">
                {{poi.poiStats.firstVisit | date:'dd/MM/yyyy H:mm:ss' }}
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12 col-lg-2">
                <b>last visit</b>
            </div>
            <div class="col" style="text-align: right;">
                {{poi.poiStats.lastVisit | date:'dd/MM/yyyy H:mm:ss' }}
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12 col-lg-2">
                <b>visit count</b>
            </div>
            <div class="col" style="text-align: right;">
                {{poi.poiStats.visitCount }}
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12 col-lg-2">
                <b>average stay</b>
            </div>
            <div class="col" style="text-align: right;">
                {{poi.poiStats.averageStay*1000 | date:'H:mm:ss':'UTC' }}
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h4>weekly stats</h4>
                <div>
                    <div style="display: block;">
                        <canvas baseChart [datasets]="barChartDataDays" [labels]="barChartLabelsDays" [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType">
                </canvas>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <h4>daily stats</h4>
                <div>
                    <div style="display: block;">
                        <canvas baseChart [datasets]="barChartDataHours" [labels]="barChartLabelsHours" [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend" [chartType]="barChartType">
                    </canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #elseBlock>
    <p style="text-align: center;">No POI selected.</p>
</ng-template>

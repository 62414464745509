import { Observable, Subject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { UserService } from './../../core/services/user/user.service';
import { Poi } from './../../core/model/trace/poi';
import { TraceDay } from './../../core/model/trace/traceDay';
import { TraceService } from './../../core/services/trace/trace.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router  } from '@angular/router'
import { Position } from 'src/app/core/model/trace/position';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Point } from 'src/app/core/model/trace/point';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { Options } from "@angular-slider/ngx-slider";
import { MatSliderChange } from '@angular/material/slider';
import { FeatureComponent, GeometryPointComponent } from 'ngx-openlayers';


@Component({
  selector: 'app-trace',
  templateUrl: './trace.component.html',
  styleUrls: ['./trace.component.css'],
  animations: [
    trigger('collapse-left', [
        state('closed', style({
            transform: "translateX(-90%)",
        })),
        state('open', style({
            transform: "translateX(5%)"
        })),
        transition('* => *', animate(150))
    ]),
    trigger('collapse-right', [
      state('closed', style({
          transform: "translateX(100%)",
      })),
      state('open', style({
          transform: "translateX(-50px)"
      })),
      transition('* => *', animate(150))
  ])
]
})
export class TraceComponent implements OnInit {

  @ViewChild(MatCalendar, {static: false}) calendar: MatCalendar<Date>;

  obsStartDate = new Subject<Date>();
  obsStartView = new Subject<String>();
  obsCurrentPosition = new Subject<Position>();

  faLeft = faAngleLeft;
  faRight = faAngleRight;
  faCalendarIcon = faAngleLeft;
  faPoiIcon = faAngleLeft;

  calendarState = "open";
  poiState: string = "closed";
  currentTime : Date;
  currentPositionValue : Position;
  uid: String;
  year: number;
  month: number;
  day: number;
  epsilon : number = 0.05;
  possibleEpsilon = [0.1,0.05,0.01];
  obfuscation : boolean = false;
  isDateSelected : boolean = false;
  elementPos : Point;
  timetable: TraceDay[];
  positions: Position[];
  obfpositions: Position[];
  granularity: number = 1800;
  possibleGranularity = [600,1800,3600];
  selectedDate : Date;
  selectedPoi : Poi;
  centerX : number = 2.19;
  centerY : number = 48.52;
  zoomLevel : number = 6;
  defaultUid : String = "01286cfad288463c076e771cd7ad1b3ed2b2feea73ca3f4f36f84497ba9a1d31";
  posArray : any[][];
  obfposArray : any[][];
  options : Options = {
    showTicksValues : true,
    stepsArray: [
      {value : 600, legend : "<small><i>10min</i></small>"},
      {value : 1800, legend : "<small><i>30min</i></small>"},
      {value : 3600, legend : "<small><i>1H</i></small>"}
    ]
  }

  dateFilter = (date : Date): boolean => {
        return true;
    };
  startDate = () : Observable<Date> => {return this.obsStartDate};
  startView = () : Observable<String> => {return this.obsStartView};
  currentPosition = () : Observable<Position> => {return this.obsCurrentPosition}


  constructor(private router: Router, private route: ActivatedRoute, private traceService: TraceService, private userService: UserService, private location : Location, private activatedRoute : ActivatedRoute) {}

  
  ngOnInit(): void {
    this.uid = this.defaultUid
    this.obsStartDate.next(new Date(2010,1,1));
    this.obsStartView.next("multi-year");
    this.userService.testToken().subscribe((res)=>{
      if(res){
        var paramMap = combineLatest([this.route.paramMap, this.route.queryParamMap]).pipe(map(([param, queryParam]) => ({params : param, queryParams : queryParam})));
        paramMap.subscribe((combinedParam) => {
          var params = combinedParam.params;
          var queryParams = combinedParam.queryParams;

          //Query Params
          if(queryParams.has("granularity")){
            this.granularity = (this.possibleGranularity.indexOf(+queryParams.get("granularity")) >= 0) ? (+queryParams.get("granularity")) : 1800;
          } 
          if(queryParams.has("epsilon")){
            this.granularity = (this.possibleEpsilon.indexOf(+queryParams.get("epsilon")) >= 0) ? (+queryParams.get("epsilon")) : 0.05;
          } 
          //Route Params
          this.year = +params.get('year')
          this.month = +params.get('month')
          this.day = +params.get('day')
          this.selectedDate = new Date(this.year, this.month-1, this.day)
          if(this.day && this.month && this.year){
            this.isDateSelected = true;
            this.getPositions();
          }  
          this.getTimetable();
        });
      }else{
        this.router.navigate(['/login']);
      }
    });
  }


  getPositions() : void {
    this.traceService.getPositions(this.granularity, this.year, this.month, this.day).subscribe(positions => {
      this.positions = positions;
      this.currentTime = positions[0].timestamp;
      this.currentPositionValue = positions[0];
      this.obsCurrentPosition.next(this.currentPositionValue);
      this.createPosArray();
      this.getCenters();
      this.getZoomLevel();
    });
    this.getObfPositions();    
  }

  getObfPositions() : void {
    this.traceService.getObfPositions(this.granularity, this.year, this.month, this.day, this.epsilon).subscribe(positions => {
      this.obfpositions = positions;
      this.createObfPosArray();
    });
  }

  getTimetable() : void {
    this.traceService.getTimetable().subscribe(timetable => {
      this.timetable = timetable;
      this.dateFilter = (date : Date) : boolean => {
        return this.isInTimetable(date);
      }
      this.obsStartDate.next(this.startDateInternal());
      this.obsStartView.next(this.startViewInternal());
      this.calendar.activeDate = this.startDateInternal();
      this.calendar.currentView = this.startViewInternal();
      this.calendar.minDate = this.getTimetableMinDate();
      this.calendar.maxDate = this.getTimetableMaxDate();
      this.calendar.updateTodaysDate();
     });
  }

  getCenters() : void {
    var x = 0
    var y = 0
    var count = 0
    if(this.positions && this.positions.length){
      for(var p of this.positions){
        if(p.acc < 150){
          x += p.lng
          y += p.lat
          count +=1
        }
      }
      this.centerX = x/count
      this.centerY = y/count
    }
  }

  createPosArray() : void {
    if(this.positions && this.positions.length){
      this.posArray = this.positions.map(p => {
        if(p.acc < 150){
        if(p.inpoi){
          return [p.poi.lng, p.poi.lat]
        }else{
          return [p.lng, p.lat]
        }
        }
      })
    }else{
      this.posArray = [];
    }
  }

  createObfPosArray() : void {
    if(this.obfpositions && this.obfpositions.length){
      this.obfposArray = this.obfpositions.map(p => {
        if(p.acc < 150){
        if(p.inpoi){
          return [p.poi.lng, p.poi.lat]
        }else{
          return [p.lng, p.lat]
        }
        }
      })
    }else{
      this.obfposArray = [];
    }
  }

  getZoomLevel() : void{
    if(this.positions && this.positions.length){
      var max = 0
      for(const p of this.positions){
          max = Math.max(this.getDistanceFromLatLon(p.lat, p.lng, this.centerY, this.centerX), max)
      }
      this.zoomLevel = Math.max(Math.min(Math.floor(Math.log2((0.5*40075016*Math.abs(Math.cos(this.centerY))/max)))-8,14), 6)
    }else{
      this.zoomLevel = 6
    }
  }

  startViewInternal() : "month" | "year" | "multi-year" {
    if(this.day && this.month && this.year){
      return "month";
    }
    return "multi-year";
  }

  startDateInternal() : Date {
    if(this.day && this.month && this.year){
      return new Date(this.year, this.month-1, this.day);
    }
    else if(this.timetable && this.timetable.length){
      const date = this.timetable[0];
      return new Date(date.year,date.month-1,date.day);
    }else{
      return  new Date(2010,0,1);
    }
  }

  toggleObf() : void {
    this.obfuscation = !this.obfuscation;
  }

  changeEpsilon(event) : void {
    this.epsilon = event.value;
    this.getObfPositions();
  }

  changeGranularity(newGranularity : number) : void {
    this.router.navigate(
      [],
      { relativeTo : this.route,
        queryParams : {granularity: newGranularity}
      }
    )
  }

  changeCurrentTime(time : MatSliderChange): void{
    this.currentTime = this.positions[time.value].timestamp;
    this.currentPositionValue = this.positions[time.value];
    this.obsCurrentPosition.next(this.currentPositionValue);
  }

  maxSliderTime() : number {
    if(this.isDateSelected && this.positions){
      return this.positions.length-1;
    }
    return 0;
  }

  getTimetableMinDate() : Date{
    if(this.timetable){
      const date = this.timetable[0];
      return new Date(date.year,date.month-1,date.day);
    }else{
      return new Date(2010,0,1);
    }
  }

  getTimetableMaxDate() : Date{
    if(this.timetable){
      const date = this.timetable[this.timetable.length-1];
      return new Date(date.year,date.month-1,date.day);
    }else{
      return new Date();
    }
  }

  selectDate(date : Date) : void {
    this.selectedPoi = null
    this.elementPos = null 
    this.isDateSelected = true;
    this.router.navigate(
      ["/trace/", date.getFullYear(), date.getMonth()+1, date.getDate()],
      { queryParamsHandling: 'preserve' }
      );
    /*
    this.selectedDate = date;
    this.day = date.getDate();
    this.month = date.getMonth()+1;
    this.year = date.getFullYear();
    if(this.day && this.month && this.year){
      this.getPositions();
    }
    */
  }

  deg2rad(deg) : number {
    return deg * (Math.PI/180)
  }

  getDistanceFromLatLon(lat1, lon1, lat2, lon2): number{
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
  }

  changeCalendarState(): void {
    (this.calendarState == "closed") ? this.calendarState = "open" : this.calendarState = "closed";
    if(this.calendarState == "closed"){
      this.faCalendarIcon = this.faRight;
    }else{
      this.faCalendarIcon = this.faLeft;
    }
  } 

  changePoiState(): void {
    (this.poiState == "closed") ? this.poiState = "open" : this.poiState = "closed";
    if(this.poiState == "closed"){
      this.faPoiIcon = this.faLeft;
    }else{
      this.faPoiIcon = this.faRight;
    }
  } 


  selectPoi(poi : Poi) : void{
    this.selectedPoi = poi;
  }


  mapOnClick(evt) {
    const map = evt.map;
    // this bit checks if user clicked on a feature
    var features = map.getFeaturesAtPixel(evt.pixel);
    if(features){
      for(let i = 0; i < features.length; i++){
        var id = features[i].getId();
        if(id != null){
          if(id < this.positions.length){
            this.selectedPoi = this.positions[id].poi
          }else{
            this.selectedPoi = this.obfpositions[id-this.positions.length].poi
          }
          if(this.poiState == "closed"){
            this.changePoiState();
          }
          if(this.calendarState == "open"){
            this.changeCalendarState();
          }
          return;
        }
      }
    }
  }

  dateClass() : MatCalendarCellCssClasses {
    return (date: Date): MatCalendarCellCssClasses => {
      if(this.timetable != undefined){
        if (this.isInTimetable(date)) {
          return 'date-w-data';
        } else {
          return;
        }
      }
    };
  }

  isInTimetable(date: Date) : boolean {
    var ret = false
    for(var d of this.timetable){
      if(d.isDate(date)){
        ret = true
        break
      }
    }
    return ret;
  }

  changeElementPos(p: Point){
    setTimeout(()=>{
      this.elementPos = p;
    })
  }
}

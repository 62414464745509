import { UserService } from './../../core/services/user/user.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConstService } from 'src/app/core/services/const.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private userService: UserService, private constService : ConstService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        if(!environment.demo){
            if (this.userService.getToken()) {
                if (request.url.startsWith(this.constService.baseUrl)) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${this.userService.getToken()}`
                        }
                    });
                }
            }
        }
        return next.handle(request);
    }
}

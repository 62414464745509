<nav class="navbar fixed-top navbar-expand-md navbar-dark bg-primary">
	<a class="navbar-brand" href="#">Demo mobility <small> - {{ buildDate }}</small></a>
	<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
	  <span class="navbar-toggler-icon"></span>
	</button>
  
	<div class="collapse navbar-collapse" id="navbarSupportedContent">
	  <ul class="navbar-nav mr-auto">
		<ng-container *ngIf="isAuth() then authBlock else anonBlock"></ng-container>
		<ng-template #authBlock>
		<li class="nav-item">
		  <a class="nav-link" [routerLink]="['/trace']">Trace</a>
		</li>
		<li class="nav-item">
		  <a class="nav-link" [routerLink]="['/profile']">Profile</a>
		</li>
        <ng-container *ngIf="isDemo() then demoBlock else defaultBlock"></ng-container>
        <ng-template #defaultBlock>
            <li class="nav-item">
               <a class="nav-link" [routerLink]="['/survey']">Questionnaire</a>
            </li>
        </ng-template>
        <ng-template #demoBlock>
        <ng-container *ngIf="userlist.length != 0">
                <select (change)="chooseUser($event)">
                    <ng-container *ngFor="let user of userlist">
                        <option value="{{user.username}}">{{user.username}}</option>
                    </ng-container>
                </select>
        </ng-container>
        </ng-template>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/about']">about</a>
      </li>
		</ng-template>
		<ng-template #anonBlock>
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/demo']">Demo</a>
			  </li>
			  <li class="nav-item">
				<a class="nav-link" [routerLink]="['/info']">about</a>
			  </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/login']">log-in</a>
        </li>
		</ng-template>
	  </ul>
	</div>
  </nav>
	





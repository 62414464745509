import { Component, OnInit, ChangeDetectionStrategy, forwardRef, OnDestroy, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { emailValidator } from 'src/app/shared/validators/email.validator';

export interface EmailFormValues {
  value: string;
}

@Component({
  selector: 'question-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailComponent implements ControlValueAccessor, OnDestroy, OnInit  {
  @Input() required : boolean = true;
  @Input() dirty : boolean = false;
  form: FormGroup;
  subscriptions: Subscription[] = [];

  get value(): EmailFormValues {
    return this.form.value;
  }

  set value(value: EmailFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get f() {
    return this.form.controls;
  }

  get emailControl() {
    return this.form.controls.email;
  }

  constructor(private formBuilder : FormBuilder) { 
  }
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      question: (this.required ? new FormControl('', [Validators.required]) : new FormControl('', []))
    });
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  
  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { email: { valid: false, }, };
  }

  reset() {
    this.form.reset();
  }

}

<ng-container *ngIf="chartData">
    <div>
        <div>
            <div style="display: block">
            <canvas baseChart
                [datasets]="chartData"
                [labels]="chartLabel"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                [chartType]="barChartType">
            </canvas>
            </div>
        </div>
    </div>
</ng-container>
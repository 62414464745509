<div class="container" style="padding-top: 74px;">
  <div class="col-12">
    <h2 style="text-align: center;"> Demo: Inspect what your location history reveals
      about you </h2>
    <div id="abstract" class="col-12" style="text-align: center;">
      <div>
        <span>Antoine Boutet</span>,
        <span>Adrien Baud</span>,
        <span>Alexandre van Beurden</span>,
        <span>Sébastien Gambs</span>
      </div>
      <br>
      <p style="text-align: justify;">
        &emsp;Location is one of the most extensively collected personal data on mobile by applications and third-party
        services. However, how the location of users is actually processed in practice by the actors of targeted
        advertising ecosystem remains unclear. Nonetheless, these providers have a strong incentive to create very
        detailed profile of users to better monetize the collected data. End users are usually not aware about the
        strength and wide range of inference that can be performed from their mobility traces.</p>
      <p style="text-align: justify;">
        &emsp;The main objective of this demonstration is to raise the user awareness about the profiling capabilities
        related to the disclosure of their personal location data and the associated privacy and discrimination threats.
        More precisely, users are invited to analyze the location history collected and provided by Google for ensuring
        data portability (i.e., one of the new right that has appear with the General Data Protection Regulation or GDPR
        in short) and to inspect the information that can be inferred from the collected data. Then, we build and
        present to users a contextual profile combining location data with semantic information deduced from the
        mobility traces such their frequent points of interests and the associated predicted activities, their mobility
        patterns as well as their personal interests. Moreover, the rationale behind each information appearing in the
        contextual profile presented to users is also detailed to users so that can better understand how such inference
        was possible.</p>
      <p style="text-align: justify;">
        &emsp;In addition of the inference attacks presented previously, to better understand the possible
        countermeasures, users can apply a sanitization mechanism with a varying level of protection and visualize the
        impact on both the mobility traces and the associated inferred information. Finally, users are also invited to
        provide feedbacks on the accuracy of the information shown as well to quantify their level of (un)comfort with
        the disclosure of these personal information and their perception and interest on location privacy mechanisms.
        We ambition to use these feedbacks to create a new dataset containing the perception of users on location
        privacy with the hope that it can be used to design new protection mechanisms that meet their expectations.</p>
    </div>
  </div>
</div>
import {Component} from '@angular/core';

@Component({
  selector: 'ngbd-progressbar-showvalue',
  templateUrl: './progressbar-showvalue.html',
  styles: [`
    ngb-progressbar {
      margin-top: 5rem;
    }
  `]
})
export class NgbdProgressbarShowvalue {
}
<ng-container *ngIf="chartData then dataBlock else loadingBlock">
</ng-container>
<ng-template #dataBlock>
  <div>
    <div>
      <div style="display: block">
        <canvas baseChart
          [data]="chartData"
          [labels]="chartLabel"
          [chartType]="doughnutChartType">
        </canvas>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #loadingBlock>
  <div>
    <p style="text-align: center;">gathering prediction, please wait</p>
  </div>
</ng-template>
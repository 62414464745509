import { AuthGuardService } from './auth/auth-services/auth-guard.service';
import { AboutComponent } from './demo/about/about.component';
import { WaitingComponent } from './demo/waiting/waiting.component';
import { ProfileComponent } from './demo/profile/profile.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { IntroComponent } from './demo/intro/intro.component';
import { TraceComponent } from './demo/trace/trace.component';
import {LoginComponent} from './demo/login/login.component';
import { SurveyComponent } from './demo/survey/survey.component';


const routes: Routes = [
    {path: 'demo', component: IntroComponent},
    {path: 'trace', component: TraceComponent, canActivate : [AuthGuardService]},
    {path: 'login', component: LoginComponent},
    {path: 'info', component: AboutComponent},
    {path: 'about', component: WaitingComponent, canActivate : [AuthGuardService]},
    {path: 'trace/:year/:month/:day', component: TraceComponent, canActivate : [AuthGuardService]},
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService] },
  { path: 'survey', component : SurveyComponent, canActivate: [AuthGuardService]},
    {path: '', redirectTo: '/demo', pathMatch: 'full' },
    { path: '**', redirectTo: '/demo' }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]

})
export class RoutingModule { }


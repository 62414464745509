<ng-container *ngIf="name">
  <hr>
  <div class="form-inline" [formGroup]="form">
    <div class="form-group row">
      <div class="col-form-label col-2">
        <label [attr.for]="name"><ng-content></ng-content></label>
      </div>
      <div class="col-10">
        <input type="number" class="form-control" [attr.id]="name" aria-describedby="emailHelp" [attr.placeholder]="name"  formControlName="question"/>
      </div>
    </div>
    </div>
    <ng-container *ngIf="dirty && f.question.invalid"><hr style="background-color: rgb(255, 89, 89);"></ng-container>
</ng-container>
import { Element } from './../../core/model/trace/element';
import { Poi } from './../../core/model/trace/poi';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { Point } from 'src/app/core/model/trace/point';
import { TraceService } from 'src/app/core/services/trace/trace.service';
import { OpenstreetcamService } from 'src/app/core/services/openstreetcam/openstreetcam.service';

@Component({
  selector: 'app-poi-detail',
  templateUrl: './poi-detail.component.html',
  styleUrls: ['./poi-detail.component.css']
})
export class PoiDetailComponent implements OnInit {

  @Input() poi : Poi;
  @Input() granularity : number;
  @Output() latlngChanged : EventEmitter<Point> = new EventEmitter();

  public image_found: boolean = false;
  public image_url: string;
  public selectedElement : Element;
  public selectedElementId : number;
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}],
              yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            precision: 0
                        }
                      }] 
            },
  };
  public barChartLabelsDays: Label[] = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
  public barChartLabelsHours: Label[] = ['00:00', '00:30','01:00', '01:30', '02:00', '02:30','03:00','03:30','4:00',
                                          '04:30', '05:00', '05:30', '06:00', '06:30','07:00' ,'07:30', '08:00','08:30',
                                          '09:00','09:30','10:00','10:30','11:00','11:30', '12:00','12:30','13:00', '13:30',
                                          '14:00','14:30','15:00','15:30','16:00','16:30', '17:00','17:30', '18:00', '18:30',
                                          '19:00', '19:30', '20:00', '20:30', '21:00', '21:30','22:00', '22:30', '23:00', '23:30'];
  public barChartColors:Array<any> = [{
    backgroundColor:  'rgba(0, 123, 255, 0.5)'
  }];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartDataDays: ChartDataSets[] = [
    { data: [] }
  ];
  public barChartDataHours: ChartDataSets[] = [
    { data: [] }
  ];

  private nextTimeout;
  constructor(private traceService: TraceService, private oscService : OpenstreetcamService) { }
  public createHourData() : void {
    if(!this.poi){
      this.barChartDataHours =  [{ data: [] }];
      return
    }
    var data = [];
    this.poi.hourStats.forEach(d => {
      data.push(d.stat)
    }) 
    this.barChartDataHours =  [{data: data }];
  }

  public createDayData() : void {
    if(!this.poi){
       this.barChartDataDays = [{ data: [] }];
       return
    }
    var data = [];
    this.poi.dayStats.forEach(d => {
      data.push(d.stat)
    }) 
    this.barChartDataDays = [{data: data }];
  }

  ngOnInit(): void {
  }

  ngOnChanges() : void {
    this.cancelReloadPoi();
    this.createDayData();
    this.createHourData();
    this.getOSCImage();
    if(this.poi){
      if(this.poi.elements.length > 0){
        this.preparePoi()
      }else{
        this.reloadPoi()
      }
    }
  }
  getOSCImage() {
    this.image_found = false;
    this.oscService.getNearestImage(this.poi.lat, this.poi.lng).subscribe((res) => {
      if (res.status) {
        this.image_found = true;
        this.image_url = res.url;
      }
    })
  }

  preparePoi() : void {
    this.poi.elements.sort((a,b) => b.confidence - a.confidence)
    this.selectedElement = this.poi.elements[0].element
    this.selectedElementId = 0
    this.latlngChanged.emit(new Point(this.selectedElement.lat, this.selectedElement.lng))
  }

  reloadPoi() : void {
    this.traceService.getPoi(this.granularity, this.poi.id).subscribe(poi =>{
      this.poi = poi;
    })
    if(this.poi.elements.length == 0){
      this.nextTimeout = setTimeout(()=>{
        this.nextTimeout = null;
        this.reloadPoi()
      }, 15000);
    }else{
      this.nextTimeout = null;
      this.preparePoi()
    }
  }
  
  cancelReloadPoi() : void {
    if(this.nextTimeout){
      clearTimeout(this.nextTimeout)
    }
  }
  selectElement(value : number){
    this.selectedElement = this.poi.elements[value].element
    this.selectedElementId = value
    this.latlngChanged.emit(new Point(this.selectedElement.lat, this.selectedElement.lng))
  }

}

import { UserService } from './../../core/services/user/user.service';
import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(public userService : UserService, public router: Router) { }

  canActivate(): boolean {
    if (!this.userService.isAuth()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}

import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstService } from '../const.service';
import { AuthResponse } from '../../model/auth/authResponse';
import { AuthCheck } from '../../model/auth/authCheck';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { User } from '../../model/auth/user';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private username: string;
    private password: string;
    private token: string;

    constructor(private http: HttpClient, private constService: ConstService, public jwtHelper: JwtHelperService) { }

    isAuth(): boolean {
        console.log(environment.demo)
        return environment.demo || !this.jwtHelper.isTokenExpired(this.getToken());
    }

    getUserlist(): Observable<User[]> | undefined {
        return this.http.get<User[]>(`${this.constService.baseUrl}/userlist`).pipe(catchError(error => {
            if (error.error instanceof ErrorEvent) {
                console.log(`Error: ${error.error.message}`);
            } else {
                console.log(`Error: ${error.message}`);
            }
            return of<User[]>([]);
        }))
            .pipe(map(items => items.map(item => new User(item))))
    }

    getToken(): string | undefined {
        if (this.token) {
            return this.token;
        } else {
            const token = localStorage.getItem("token");
            if (token) {
                this.token = token;
                return this.token;
            }
            return undefined;
        }

    }

    setToken(token: string | undefined): void {
        this.token = token;
        if (token) {
            localStorage.setItem("token", token);
        } else {
            localStorage.removeItem("token");
        }
    }

    getUsername(): string | undefined {
        if (this.username) {
            return this.username;
        } else {
            const username = localStorage.getItem("username");
            if (username) {
                this.username = username;
                return this.username;
            }
        }
        return undefined;
    }

    setUsername(username: string | undefined): void {
        this.username = username;
        if (username) {
            localStorage.setItem("username", username);
        } else {
            localStorage.removeItem("username");
        }
    }

    getPassword(): string | undefined {
        if (this.password) {
            return this.password;
        }
        return undefined;
    }

    clearPassword() {
        this.password = undefined;
    }


    generateToken(username: string, password: string) {
        this.setUsername(username);
        this.password = password;
        return new Observable<boolean>((obs) => {
            this.http.post<AuthResponse>(`${this.constService.baseUrl}/authenticate`, { username: this.username, password: password })
                .pipe(catchError(error => {
                    if (error.error instanceof ErrorEvent) {
                        console.log(`Error: ${error.error.message}`);
                    } else {
                        console.log(`Error: ${error.message}`);
                    }
                    this.setUsername(undefined);
                    this.setToken(undefined);

                    var res = new AuthResponse();
                    res.token = null;
                    return of(res);
                })).subscribe((res: AuthResponse) => {
                    if (res.token) {
                        this.setToken(res.token);
                        obs.next(true);
                    } else {
                        obs.next(false);
                    }
                });
        });
    }

    testToken(): Observable<boolean> {
        if (environment.demo) {
            return of(true);
        }

        if (this.getToken()) {
            return this.testTokenInternal();
        } else if (this.getUsername() && this.password) {
            this.generateToken(this.username, this.password).subscribe((res) => {
                return this.testTokenInternal();
            });
        } else {
            return of(false);
        }
    }

    private testTokenInternal(): Observable<boolean> {
        return new Observable<boolean>((obs) => {
            if (this.getToken()) {
                this.http.get<AuthCheck>(`${this.constService.baseUrl}/${this.getUsername()}/check`)
                    .pipe(catchError((error) => {
                        if (error.error instanceof ErrorEvent) {
                            console.log(`Error: ${error.error.message}`);
                        } else {
                            console.log(`Error: ${error.message}`);
                        }
                        this.setToken(undefined);
                        var res = new AuthCheck()
                        res.ok = false;
                        res.processed = false;
                        return of(res);
                    })).subscribe((res) => {
                        if (res.ok) {
                            obs.next(true);
                        } else {
                            obs.next(false);
                            this.setToken(undefined);
                        }
                    });
            } else {
                return obs.next(false);
            }
        });
    }

    isProcessed(): Observable<boolean> {
        return new Observable<boolean>((obs) => {
            if (this.getToken()) {
                this.http.get<AuthCheck>(`${this.constService.baseUrl}/${this.getUsername()}/check`)
                    .pipe(catchError((error) => {
                        if (error.error instanceof ErrorEvent) {
                            console.log(`Error: ${error.error.message}`);
                        } else {
                            console.log(`Error : ${error.message}`);
                        }
                        var res = new AuthCheck()
                        res.ok = false;
                        res.processed = false;
                        return of(res);
                    })).subscribe((res) => {
                        return obs.next(res.processed)
                    })
            } else {
                return obs.next(false);
            }
        })
    }

    isSurveyDone(): Observable<boolean> {
        return new Observable<boolean>((obs) => {
            if (this.getToken()) {
                this.http.get<AuthCheck>(`${this.constService.baseUrl}/${this.getUsername()}/check`)
                    .pipe(catchError((error) => {
                        if (error.error instanceof ErrorEvent) {
                            console.log(`Error: ${error.error.message}`);
                        } else {
                            console.log(`Error : ${error.message}`);
                        }
                        var res = new AuthCheck()
                        res.ok = false;
                        res.processed = false;
                        return of(res);
                    })).subscribe((res) => {
                        return obs.next(res.surveydone)
                    })
            } else {
                return obs.next(false);
            }
        })
    }
}

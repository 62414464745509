import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SurveyResp } from '../../model/survey/surveyResp';
import { ConstService } from '../const.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(
    private http: HttpClient, private constService: ConstService, private userService : UserService) { }

    saveSurvey(survey : any) : Observable<boolean>{
      return new Observable<boolean>((obs) => {
        this.http.post<SurveyResp>(`${this.constService.baseUrl}/survey`, survey)
        .pipe(catchError(error => {
          if (error.error instanceof ErrorEvent) {
              console.log( `Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }
          var resp = new SurveyResp()
          resp.ok = false;
          return of<SurveyResp>(resp);
        }
        ))
        .subscribe((res : SurveyResp) => {
          console.log(res)
          if(res.ok){
            obs.next(true)
          }else{
            obs.next(false);
          }
        });
      });
    }
}

import { Component, OnInit, OnDestroy, forwardRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface TextAreaQuestionValues {
  value: string;
}

@Component({
  selector: 'question-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextAreaComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() name: string;
  @Input() required : boolean = true;
  @Input() dirty : boolean = false;
  form: FormGroup;
  subscriptions: Subscription[] = [];

  get value(): TextAreaQuestionValues {
    return this.form.value;
  }

  set value(value: TextAreaQuestionValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get f() {
    return this.form.controls;
  }

  constructor(private formBuilder : FormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      question : (this.required ? new FormControl('', [Validators.required]) : new FormControl('', []))
    });
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { question: { valid: false, }, };
  }

  reset() {
    this.form.reset();
  }

  /** ControlValueAccessor */
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /* ControlValueAccessor **/


}

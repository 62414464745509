import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownDirective } from "src/app/shared/directives/dropdown.directive";
import { ModalComponent } from './modal/modal/modal.component';
import { LangPipe } from './pipes/lang.pipe';
import { PaginationPipe } from './pipes/pagination.pipe';
import { FileUploaderModule } from './file-uploader/file-uploader.module';
//import { PaginationModule } from './pagination/pagination.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdDatepickerPopupModule } from './datepicker/datepicker-popup.module';
import { ProgressbarShowvalueModule } from './progressbar-showvalue/progressbar-showvalue.module';
import { PoiDetailComponent } from './poi-detail/poi-detail.component';
import { ChartsModule } from 'ng2-charts';
import { StaticMapComponent } from './static-map/static-map.component';
import { AngularOpenlayersModule } from "ngx-openlayers";

const declarations = [
      DropdownDirective,
      ModalComponent,
      LangPipe,
      PaginationPipe,
      PoiDetailComponent, 
      StaticMapComponent
//      DatepickerComponent
//      FileUploaderComponent
//      FileUploadModule
]

@NgModule({
    imports: [CommonModule, FileUploaderModule, NgbModule, ProgressbarShowvalueModule, NgbdDatepickerPopupModule, ChartsModule, AngularOpenlayersModule],
    declarations ,
    exports: [...declarations, FileUploaderModule, ProgressbarShowvalueModule, PoiDetailComponent, StaticMapComponent],
})
export class SharedModule { }

<app-about></app-about>

<div class="container">
    <div class="Login-info col-12">
        <ng-container *ngIf="password">
            <ng-container *ngIf="processed then doneBlock else processingBlock"></ng-container>
            <ng-template #processingBlock>
                <strong>Your data is being processed, once done you'll be able to review your traces & inferences on Trace & Profile pages.</strong><br>
            </ng-template>
            <ng-template #doneBlock>
                <strong>Your data is done processing, you can review your traces and the infered profile. The obfuscation is a slower process and might not be done yet.</strong><br>
            </ng-template>
            your log-in information to review your traces in the future : <br>
            <strong>UID :</strong>{{username}}<br>
            <strong>password :</strong>{{password}}<br>
        </ng-container>
    </div>
</div>
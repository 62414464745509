<hr>
<div class="form-inline" [formGroup]="form">
  <div class="form-group row">
    <div class="col-form-label col-4">
      <label [attr.for]="name"><ng-content></ng-content></label>
    </div>
    <div class="col-8">
      <mat-select-country formControlName="question"></mat-select-country>
    </div>
  </div>
  <ng-container *ngIf="dirty && f.question.invalid"><hr style="background-color: rgb(255, 89, 89);"></ng-container>
</div>
import {Component, Input} from '@angular/core';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDateStruct, NgbCalendar, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
//import { NgbDateCustomParserFormatter} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-datepicker-popup',
  templateUrl: './datepicker-popup.html'
})
export class NgbdDatepickerPopup {
    @Input() model;
    @Input() creationDate;

//    model2           = 
//    {
//        year : this.present.getFullYear(), 
//        month: this.present.getMonth()+1, 
//        day  : this.present.getDate()
//    };
//
//    placeHolderText = this.model2.year + '-' + this.model2.month + '-' + this.model2.day;
  
//  date = { year: 1980, month: 7, day: 12 };
  
//  @Input() displayMonths: number;

//    constructor(private calendar: NgbCalendar) {
//    }
//  
//  
//  onDateSelect(ev: Event) {
//      console.log("****** event : " + JSON.stringify(ev))
//  }

}


//import { Component, Input } from '@angular/core';
//import { FormsModule } from '@angular/forms';
//import { ReactiveFormsModule } from '@angular/forms';
//import { NgbDateStruct, NgbCalendar, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
//
//@Component({
//  selector: 'app-datepicker',
//  templateUrl: './datepicker.component.html'
//})
//export class DatepickerComponent {
//  model: NgbDateStruct;
//  date: {year: number, month: number};
//  
//  @Input() creationDate: Date;
//  displayMonths = 3;
//  navigation = 'select';
//  showWeekNumbers = true;
//  outsideDays = 'visible';
////  startDate = { year: 1980; month: 7; day: 12 };
//  
//
//  constructor(private calendar: NgbCalendar) {
//  }
//
//  selectToday() {
//    this.model = this.calendar.getToday();
//    console.log("****** this.model : " + JSON.stringify(this.model));
//
//  }
//  

//}
//
//  getToday() {
//      console.log("****** event : " + NgbCalendar.getToday());
//  }
  
//  @Input() creationDate: NgbDate = new NgbDate(1789, 7, 14);  
//}





//import { Component, OnInit } from '@angular/core';
//import { FormControl, ValidationErrors } from "@angular/forms";
//import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
//
//
//@Component({
//  selector: 'app-datepicker',
//  templateUrl: './datepicker.component.html',
//  styleUrls: ['./datepicker.component.css']
//})
//export class DatepickerComponent {
//
//    model: NgbDateStruct;
//    displayMonths = 2;
//    navigation = 'select';
//    showWeekNumbers = false;
//    outsideDays = 'visible';
//
//
////    date: {year: number, month: number};
//    
//    constructor(private calendar: NgbCalendar) {
//    }
//    
////    selectToday() {
////      this.model = this.calendar.getToday();
////    }
////    
//
//
//}

import { Observable } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';


@Component({
  selector: 'personality-chart',
  templateUrl: './personality-chart.component.html',
  styleUrls: ['./personality-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalityChartComponent implements OnInit {

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    scale : {
      ticks : {
        min : 0,
        max : 100
      }
    }
  };
  public radarChartLabels: Label[] = ['openness', 'consciousness', 'extraversion', 'agreability', 'neuroticism'];
  public radarChartType: ChartType = 'radar';

  @Input()
  public data :ChartDataSets[];

  constructor() {
   }

  ngOnInit(): void {
  }

}

<style>

button.calendar, button.calendar:active {
  width: 2.75rem;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=') !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}


</style>

<form class="form-inline">
  <div class="form-group">
    <div class="input-group">
      <input class="form-control"
             name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
      </div>
    </div>
  </div>
</form>

<!-- <pre>Model: {{ model | json }}</pre> -->



<!-- <div class="col-6"> -->
<!-- <form class="form-inline"> -->
<!--   <div class="form-group"> -->
<!--     <div class="input-group"> -->
<!--    		<input type="text" class="form-control" placeholder="yyyy-mm-dd" name="dp" [displayMonths]="displayMonths" [navigation]="navigation"  -->
<!--    		[outsideDays]="outsideDays" [showWeekNumbers]="showWeekNumbers"  -->
<!--    		[startDate]="startDate" -->
<!--    		(dateSelect)="onDateSelect($event)" -->
<!--    		ngbDatepicker #d="ngbDatepicker"> -->
<!--       <div class="input-group-append"> -->
<!--         <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button> -->
<!--       </div> -->

<!--     </div> -->

<!--   </div> -->

<!-- </form> -->

<!--    <hr/> -->
<!-- <button class="btn btn-sm btn-outline-primary mr-2" (click)="selectToday()">Select Today</button> -->
<!--     <hr/> -->

<!-- </div> -->


<!-- <ngb-datepicker [displayMonths]="displayMonths" [navigation]="navigation" -->
<!--                 [showWeekNumbers]="showWeekNumbers" [outsideDays]="outsideDays"> -->
<!-- </ngb-datepicker> -->

<!-- <hr/> -->

<!-- <form class="form-inline"> -->
<!--   <div class="form-group"> -->
<!--     <div class="input-group"> -->
<!--       <input class="form-control" placeholder="yyyy-mm-dd" -->
<!--              name="dp" [displayMonths]="displayMonths" [navigation]="navigation" [outsideDays]="outsideDays" -->
<!--              [showWeekNumbers]="showWeekNumbers" ngbDatepicker #d="ngbDatepicker"> -->
<!--       <div class="input-group-append"> -->
<!--         <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button> -->
<!--       </div> -->
<!--     </div> -->
<!--   </div> -->
<!-- </form> -->

<!-- <hr/> -->

<!-- <div class="d-flex flex-wrap align-content-between p-2"> -->
<!--   <select class="custom-select" [(ngModel)]="displayMonths"> -->
<!--     <option [ngValue]="1">One month</option> -->
<!--     <option [ngValue]="2">Two months</option> -->
<!--     <option [ngValue]="3">Three months</option> -->
<!--   </select> -->

<!--   <select class="custom-select" [(ngModel)]="navigation"> -->
<!--     <option value="none">Without navigation</option> -->
<!--     <option value="select">With select boxes</option> -->
<!--     <option value="arrows">Without select boxes</option> -->
<!--   </select> -->

<!--   <select class="custom-select" [(ngModel)]="showWeekNumbers"> -->
<!--     <option [ngValue]="true">Week numbers</option> -->
<!--     <option [ngValue]="false">No week numbers</option> -->
<!--   </select> -->

<!--   <select class="custom-select" [(ngModel)]="outsideDays"> -->
<!--     <option value="visible">Visible outside days</option> -->
<!--     <option value="hidden">Hidden outside days</option> -->
<!--     <option value="collapsed">Collapsed outside days</option> -->
<!--   </select> -->
<!-- </div> -->

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { OSCImage } from '../../model/openstreetcam/image';
import { OSCNearbyResponse } from '../../model/openstreetcam/nearby-response';
import { catchError } from 'rxjs/operators';
import { OSCStatus } from '../../model/openstreetcam/status';

@Injectable({
  providedIn: 'root'
})
export class OpenstreetcamService {

  constructor(
    private http: HttpClient) { }
  
  getNearestImage(lat: number, lng: number, radius: number = 500): Observable<OSCImage> {
    return new Observable<OSCImage>((obs) => {
      var postbody: string;
      postbody = "lat=" + lat + "&lng=" + lng + "&radius=" + radius;
      let headers = new HttpHeaders();
      headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
      this.http.post<OSCNearbyResponse>("https://openstreetcam.org/1.0/list/nearby-photos/", postbody, { headers : headers })
        .pipe(catchError(error => {
          if (error.error instanceof ErrorEvent) {
            console.log(`Error : ${error.error.message}`);
          } else {
            console.log(`Error : ${error.message}`);
          }
          var res = new OSCNearbyResponse()
          var status = new OSCStatus()
          status.apiCode = 0;
          status.httpCode = 0;
          res.status = status;
          return of(res);
        })).subscribe((res) => {
          var image = new OSCImage();
          image.status = false;
          if (res.status.apiCode == 600 && res.status.httpCode == 200) {
            if (res.currentPageItems.length > 0) {
              image.status = true;
              image.url = "https://openstreetcam.org/" + res.currentPageItems[0].th_name;
            }
          }
          obs.next(image);
        })
    });
  }
}

import { PipeTransform, Pipe } from "@angular/core";

const WORDS = {
    // Login
    LOGIN: { en: 'Login' },
    SIGNIN: { en: 'Sign in' },
    SIGNUP: { en: 'Sign up' },
    SIGNOUT: { en: 'Sign out' },
    WELCOME: { en: 'Welcome to PITTA plateform' },

    // NavBar
    HOME: { en: 'Home' },
    MY_ACCOUNT: { en: 'My account' },
    USERS: { en: 'Users' },
    CREDENTIALS: { en: 'Credentials' },
    DISCONNECT: { en: 'Disconnect' },
    LISTS: { en: 'Lists' },
    ADMIN: { en: 'Admin' },
    ROLES: { en: 'Roles' },
    YES: { en: 'Yes' },
    NO: { en: 'No' },
   
    USER_DISCONNECT: { en: 'You are disconnected from PITTA. If you want to reconnect click on the button below.' },
    RECONNECT: { en: 'Reconnect to PITTA' },
    REGISTRATION_PENDING_1: { en: 'Your request is being processed.' },
    REGISTRATION_PENDING_2: { en: 'You will recieve an email containing a token.' },
    REGISTRATION_PENDING_3: { en: 'Please click on it and you will be able to connect to PITTA platform.' },

    // Screen titles
    PITTA: { en: 'PITTA' },
    ACCOUNT_REQUEST : { en: 'account request' },
    
    // General
    OK: { en: 'Ok' },
    CANCEL: { en: 'Cancel' },
    ACTION: { en: 'Action' },
    DETAILS: { en: 'Details' },
    DELETE: { fr: 'Supprimer', en: 'Delete' },
    REMOVE: { en: 'Remove' },
    SUBMIT: { en: 'Submit' },
    EDIT: { en: 'Edit' },
    IS_REQUIRED: { en: 'is required' },
    CREATE: { en: 'Create'}, 
    GO_BACK: { en: 'Go back' },
    ID: { en: 'ID' },
    
    // User management module
    A_USER: { en: 'a user'},
    A_CREDENTIAL: { en: 'a credential'}, 
    A_GROUP: { en: 'a group'}, 
    A_GROUP_TYPE: { en: 'a group type'}, 
    A_ROLE: { en: 'a role'}, 
    LABEL: { en: 'Label'}, 

    ADD_ROLE: { en: 'Add a role to the user'},
    ADD_USER_IN_GROUP: { en: 'Add the user in this group'},
    USER_ACCOUNT_REQUEST: { en: 'I request a PITTA account' },

    GENERAL_CHARACTERISTICS: { en: 'General characteristics' },
    PERSONAL_INFORMATIONS: { en: 'Personal informations' },
    FIRSTNAME: { en: 'Firstname' },
    LASTNAME: { en: 'Lastname' },
    EMAIL: { en: 'Email' },
    REEMAIL: { en: 'Email (verification)' },
    USERNAME: { en: 'Username' },
    EXPIRATION_DATE: { en: 'Expiration date' },
    CREATION_DATE: { en: 'Creation date' },
    APPROVED_DATE: { en: 'Approved date' },
    IS_ACTIVE: { en: 'Is active' },
    IS_ADMIN: { en: 'Is admin' },
    PHONE_NUMBER: { en: 'Phone number' },
    INSTANT_MESSAGING: { en: 'Instant Messaging' },
    CHARTER_ACCEPTANCE_DATE: { en: 'Charter acceptance date' },
    USER_ACCEPT_CHARTER: { en: 'I accept the PITTA charter.' },
    EMPLOYER_NUMBER_USERS: { en: 'Number of users' },
    ROLE_NAME: { en: 'Role name' },
    GROUP: { en: 'Group' },
    GROUPS: { en: 'Groups' },
    MANAGED_GROUPS: { en: 'Groups managed by the user' },
    GROUP_TYPES: { en: 'Group types' },
    GROUP_TYPE: { en: 'Group type' },
    GROUP_TYPE_NUMBER_GROUPS: { en: 'Number of concerned groups' },
    GROUP_NAME: { en: 'Group name' },
    GROUP_NUMBER_USERS: { en: 'Number of users in the group' },
    GROUP_NUMBER_MANAGERS: { en: 'Number of managers of the group' },
    GROUP_MANAGERS: { en: 'Group managers' },
    GROUP_USERS: { en: 'Group users' },
    NO_GROUP_CONCERNED_BY_GROUP_TYPE: { en: 'There is no group concerned by this group type.' },
    NO_MANAGER_OF_GROUP: { en: 'This group has no manager.' },
    NO_USER_IN_GROUP: { en: 'There is no user in this group.' },

    TEXT_ADDRESS: { en: 'Address' },
    CITY: { en: 'City' },
    ZIP_POSTAL_CODE: { en: 'ZIP / Postal code' },
    STATE_PROVINCE_REGION: { en: 'State / Province / Region' },
    COUNTRY: { en: 'Country' },
    
    USER_CHARTER_ACCEPTANCE: { en: 'Charter acceptance' },
    VALIDATION: { en: 'Validation' },
    
    // Credential
    CREDENTIAL: { en: 'Credential' },
    CREDENTIAL_S: { en: 'Credential(s)' },
    PASSWORD: { en: 'Password' },
    REPASSWORD: { en: 'Password (verification)' },
    SSHKEY: { en: 'SSH key' },
    GROUP_S: { en: 'Group(s)' },
    PUBLICATION_S: { en: 'Publication(s)' },

    // Deletion
    DELETE_CONFIRMATION: { en: 'You are going to delete ' },
    USER_DELETION: { en: 'User deletion' },
    DELETE_CONFIRMATION_USER: { en: 'the user : ' },
    DELETE_CONFIRMATION_USER_USERNAME: { en: 'who\'s username is : ' },
    
    CREDENTIAL_DELETION: { en: 'Credential deletion' },
    DELETE_CONFIRMATION_CREDENTIAL: { en: 'the credential : ' },
    
    ROLE_DELETION: { en: 'Role deletion' },
    DELETE_CONFIRMATION_ROLE: { en: 'the role : ' },
    DELETE_CONFIRMATION_ROLE_NAME: { en: 'who\'s role name is : ' },
    
    GROUP_DELETION: { en: 'Group deletion' },
    DELETE_CONFIRMATION_GROUP: { en: 'the group : ' },
    DELETE_CONFIRMATION_GROUP_NAME: { en: 'who\'s group name is : ' },
    
    GROUP_TYPE_DELETION: { en: 'Group type deletion' },
    DELETE_CONFIRMATION_GROUP_TYPE: { en: 'the group type : ' },
    DELETE_CONFIRMATION_GROUP_TYPE_NAME: { en: 'who\'s group type name is : ' },

    DROPDOWN_LIST_SELECT_GROUP_TYPE: { en: 'Select a group type if need it' },
    DROPDOWN_LIST_SELECT_GROUP: { en: 'Select a group' },
    
//  Report management
    REPORT_A_DEVICE: { en: 'Report a device' },
    VALIDATE_REPORTED_DEVICE: { en: 'Validate the reported device' },
    RESET_ALL_VALUES: { en: 'Reset all values' },

    DEVICE_TYPE: { en: 'Device type' },
    DROPDOWN_LIST_SELECT_DEVICE_TYPE: { en: 'Select a device type' },
    DEVICE_TYPE_SENSOR: { en: 'Sensor' },
    DEVICE_TYPE_BALISE: { en: 'Balise' },
    BALISE_CHARACTERISTIC: { en: 'Balise characteristic' },
    DROPDOWN_LIST_SELECT_BALISE_CHARACTERISTIC: { en: 'Select a balise characteristic' },
    BALISE_TYPE: { en: 'Balise type' },
    DROPDOWN_LIST_SELECT_BALISE_TYPE: { en: 'Select a balise type' },
    SENSOR_CHARACTERISTIC: { en: 'Sensor characteristic' },
    DROPDOWN_LIST_SELECT_SENSOR_CHARACTERISTIC: { en: 'Select a sensor characteristic' },
    SENSOR_TYPE: { en: 'Sensor type' },
    DROPDOWN_LIST_SELECT_SENSOR_TYPE: { en: 'Select a sensor type' },
    REPORT_DATE: { en: 'Report date' },
    OPERATOR: { en: 'Operator' },
    OPERATOR_NAME: { en: 'Operator name' },
    OPERATOR_URL: { en: 'Operator url' },
    DROPDOWN_LIST_SELECT_OPERATOR: { en: 'Select an Operator' },
    DROPDOWN_LIST_SELECT_OPERATOR_NAME: { en: 'NAME :' },
    DROPDOWN_LIST_SELECT_OPERATOR_URL: { en: 'URL :' },
    SELECT_OR_CREATE_OPERATOR: { en: 'Select an existing Operator in the dropdown list bellow OR create a new one with \'+\' button.' },
    PLUS: { en: '+' },
    CREATE_OPERATOR: { en: 'Create a new Operator' },
    CREATE_OPERATOR_MODAL_NAME: { en: 'Operator name :' },
    CREATE_OPERATOR_MODAL_URL: { en: 'Operator URL :' },
    DEVICE_CHARACTIRISTICS: { en: 'DEVICE characteristics' },
    PROOF_CHARACTIRISTICS: { en: 'PROOF characteristics' },
    OPERATOR_CHARACTIRISTICS: { en: 'OPERATOR characteristics' },
    DEVICE_IS_ACTIVE: { en: 'Is active ?' },
    DEVICE_IS_ACTIVE_EXPLANATION: { en: 'Explanations...' },
    DEVICE_IS_VISIBLE: { en: 'Is visible ?' },
    DEVICE_IS_VISIBLE_EXPLANATION: { en: 'Explanations...' },
    DEVICE_GPS_LATITUDE: { en: 'GPS Latitude' },
    DEVICE_GPS_LONGITUDE: { en: 'GPS Longitude' },
    PROOF_PHOTO: { en: 'Photo' },
    PROOF_URL: { en: 'URL' },
    PROOF_TEXT: { en: 'Description' },
    SELECT_OR_CREATE_TRACKING_SYSTEM: { en: 'Select an existing Tracking System in the dropdown list bellow OR create a new one with \'+\' button.' },
    CREATE_TRACKING_SYSTEM: { en: 'Create a new Tracking System' },
    TRACKING_SYSTEM: { en: 'Tracking System' },
    DROPDOWN_LIST_TRACKING_SYSTEM: { en: 'Select a Tracking System' },
    
    TRACKING_SYSTEM_CHARACTIRISTICS: { en: 'TRACKING SYSTEM characteristics' },
    TRACKING_SYSTEM_CONSENTEMENT: { en: 'Consentement' },
    TRACKING_SYSTEM_DRODOWN_ITEM_TITLE: { en: 'Please, select this tracking system to see details in dropdown lists below' },
    TYPE: { en: 'Type' },
    
    PLACE_NAME: { en: 'NAME :' },
    PLACE_TAG: { en: 'TAG :' },
    PLACE_ADDRESS: { en: 'ADDRESS :' },
    PLACE_OWNER_NAME: { en: 'NAME :' },
    PLACE_OWNER_URL: { en: 'URL :' },
    PLACE_OWNER_TYPE_LABEL: { en: 'ONWER TYPE :' },
    

    SELECT_OR_CREATE_BASE_LEGALE: { en: 'Select an existing Base Legale in the dropdown list bellow OR create a new one with \'+\' button.' },
    BASE_LEGALE: { en: 'Base Legale' },
    DROPDOWN_LIST_SELECT_BASE_LEGALE: { en: 'Select an Base Legale' },
    CREATE_BASE_LEGALE: { en: 'Create a new Base Legale' },
    
    SUBJECT_RIGTH: { en: 'Subject Rigth' },
    DROPDOWN_LIST_SELECT_SUBJECT_RIGTH: { en: 'Select an Subject Rigth' },
    CREATE_SUBJECT_RIGTH: { en: 'Create a new Subject Rigth' },
    
    SELECT_OR_CREATE_PLACE: { en: 'Select an existing Place in the dropdown list bellow OR create a new one with \'+\' button.' },
    PLACE: { en: 'Place' },
    DROPDOWN_LIST_SELECT_PLACE: { en: 'Select a Place' },
    CREATE_PLACE: { en: 'Create a new Place' },
    PLACE_NAME_LOWER: { en: 'Name :' },
    PLACE_TAG_LOWER: { en: 'Tag :' },
    PLACE_ADDRESS_LOWER: { en: 'Address :' },
    
    BUTTON_CREATE: { en: 'Create' },
    BUTTON_MODIFY: { en: 'Modfy' },
    BUTTON_DELETE: { en: 'Delete' },
    
    SELECT_OR_CREATE_PLACE_OWNER: { en: 'Select an existing Place Owner in the dropdown list bellow OR create a new one with \'+\' button.' },
    PLACE_OWNER: { en: 'Place Owner' },
    DROPDOWN_LIST_SELECT_PLACE_OWNER: { en: 'Select a Place Owner' },
    CREATE_PLACE_OWNER: { en: 'Create a new Place Owner' },
    PLACE_OWNER_NAME_LOWER: { en: 'Name :' },
    
    PLACE_OWNER_TYPE: { en: 'Place Owner Type' },
    DROPDOWN_LIST_SELECT_PLACE_OWNER_TYPE: { en: 'Select a Place Owner Type' },
    CREATE_PLACE_OWNER_TYPE: { en: 'Create a new Place Owner Type' },

    TODAY: { en: 'Today' },
    CLOSE: { en: 'Close' },
    
}

@Pipe({
    name: 'lang'
})
export class LangPipe implements PipeTransform {
    transform(langId: string, lang: string) {
//        return WORDS[langId][lang]
        return WORDS[langId].en

    }
}



















import { Poi } from './poi';
export class Position {
    constructor(item: Position) {
        Object.assign(this, item);
        this.timestamp = new Date(this.timestamp)
    }

    id : number;
    uid : String;
    timestamp : Date;
    granularity : number;
    acc: number;
    lat: number;
    lng : number;
    duration: number;
    inpoi : boolean;
    poi? : Poi;
}
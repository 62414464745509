import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';

@Component({
  selector: 'app-genre-chart',
  templateUrl: './genre-chart.component.html',
  styleUrls: ['./genre-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenreChartComponent implements OnInit {

  @Input() chartData : MultiDataSet;
  @Input() chartLabel : Label[]
  // Doughnut
  public doughnutChartType: ChartType = 'doughnut';
  constructor() { }

  ngOnInit(): void {
  }

}

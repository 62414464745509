import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TraceService } from './../core/services/trace/trace.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { IntroComponent } from './intro/intro.component';
import { TraceComponent } from './trace/trace.component';
import { AngularOpenlayersModule } from "ngx-openlayers";
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartsModule } from 'ng2-charts';
import { ProfileComponent } from './profile/profile.component';
import { GenreChartComponent } from './profile/genre-chart/genre-chart.component';
import { JobChartComponent } from './profile/job-chart/job-chart.component';
import { LoginComponent } from './login/login.component';
import { PoiCardComponent } from './profile/poi-card/poi-card.component';
import { PlaceCardComponent } from './profile/place-card/place-card.component';
import { WaitingComponent } from './waiting/waiting.component';
import { AboutComponent } from './about/about.component';
import {MatSliderModule} from '@angular/material/slider';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {MatSelectModule} from '@angular/material/select';
import { PersonalityChartComponent } from './profile/personality-chart/personality-chart.component';
import { SurveyComponent } from './survey/survey.component';
import { YesnoComponent } from './survey/question/yesno/yesno.component';
import { ChoiceComponent } from './survey/question/choice/choice.component';
import { TextAreaComponent } from './survey/question/text-area/text-area.component';
import { NumberComponent } from './survey/question/number/number.component';
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import {HttpClientModule} from '@angular/common/http';
import { NationalityComponent } from './survey/question/nationality/nationality.component';
import { EmailComponent } from './survey/question/email/email.component';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    AngularOpenlayersModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FontAwesomeModule,
    ChartsModule,
    NgbModule,
    MatSliderModule,
    NgxSliderModule,
    MatSelectModule,
    MatSelectCountryModule,
    HttpClientModule
  ],
  declarations: [ IntroComponent,
                  TraceComponent,
                  ProfileComponent, GenreChartComponent, JobChartComponent, PoiCardComponent, PlaceCardComponent, PersonalityChartComponent,
                  LoginComponent,
                  WaitingComponent,
                  AboutComponent,
                  SurveyComponent, YesnoComponent, ChoiceComponent, TextAreaComponent, NumberComponent, NationalityComponent, EmailComponent],
  providers : [TraceService],
  exports: []
  
})
export class DemoModule { }

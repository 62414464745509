import { PoiStats } from './poiStats';
import { ElementConfidence } from './elementConfidence';
import { Stats } from './stats';
export class Poi{
    id : number;
    lat: number;
    lng: number;
    elements? : ElementConfidence[];
    poiStats  : PoiStats;
    hourStats : Stats[];
    dayStats : Stats[];
}
<ng-container *ngIf="name">
    <hr>
    <div class="form-group" [formGroup]="form">
        <label [attr.for]="'textArea'+name" style="text-align: justify;"><ng-content></ng-content></label>
        <div class="row">
            <div class="col-10 offset-1">
                <textarea formControlName="question" class=" form-control " [attr.id]="'textArea'+name" rows="3 "></textarea>
            </div>
        </div>
    </div>
    <ng-container *ngIf="dirty && f.question.invalid"><hr style="background-color: rgb(255, 89, 89);"></ng-container>
</ng-container>
import { catchError } from 'rxjs/operators';
import { UserService } from './../user/user.service';
import { Profile } from './../../model/profile/profile';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstService } from '../const.service';
import { Observable, of } from 'rxjs';
import { Poi } from './../../model/trace/poi';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpClient, private constService: ConstService, private userService : UserService) { }

  getProfile() : Observable<Profile>{
    return this.http.get<Profile>(`${this.constService.baseUrl}/${this.userService.getUsername()}/profile`)
    .pipe(catchError(error => {
      if (error.error instanceof ErrorEvent) { 
        console.log( `Error: ${error.error.message}`);
      } else {
        console.log(`Error: ${error.message}`);
      }
      return of<Profile>();
    }))
  
  }

  getObfProfile(epsilon : number) : Observable<Profile>{
    return this.http.get<Profile>(`${this.constService.baseUrl}/${this.userService.getUsername()}/obfsct/${epsilon}/profile`)
    .pipe(catchError(error => {
      if (error.error instanceof ErrorEvent) {
        console.log( `Error: ${error.error.message}`);
      } else {
        console.log(`Error: ${error.message}`);
      }
      return of<Profile>();
    }))
  
  }

  getAllPois() : Observable<Poi[]> {
    return this.http.get<Poi[]>(`${this.constService.baseUrl}/${this.userService.getUsername()}/pois`)
    .pipe(catchError(error => {
      if (error.error instanceof ErrorEvent) {
        console.log( `Error: ${error.error.message}`);
      } else {
        console.log(`Error: ${error.message}`);
      }
      return of<Poi[]>([]);
    }))

    }
}

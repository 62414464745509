import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { RoutingModule } from './routing.module';

import { AppComponent } from './app.component';

import { DemoModule } from './demo/demo.module';
import { LayoutModule } from './layout/layout.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { JwtInterceptor } from './auth/auth-services/jwt.interceptor';
import { AngularOpenlayersModule } from "ngx-openlayers";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {UserService} from './core/services/user/user.service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';

@NgModule({
    imports: [
      DemoModule,
      BrowserModule,
      FormsModule,
      RoutingModule,
      HttpClientModule,
      ReactiveFormsModule,
      LayoutModule,
      NgbModule,
      FileUploadModule,
      AngularOpenlayersModule,
      BrowserAnimationsModule,
      FontAwesomeModule,
      MatSelectCountryModule.forRoot('fr')
//      TooltipModule.forRoot()
//      FileUploader
    ],
    declarations: [
      AppComponent
      
    ],
    providers: [
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      UserService,
//      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
      { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
      JwtHelperService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

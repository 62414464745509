import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConstService {
//  /** URL for dev version */
public baseUrl = environment.apiUrl;

  constructor() { }
}

<ng-template #modal_download let-modal>
	<div class="modal-header">
	  <h4 class="modal-title" id="modal-basic-title">How can I get my mobility traces?</h4>
	  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	  </button>
	</div>
	<div class="modal-body">
		<b>Location History: how to download my data</b>
		<ul>
			<li class="modal-li">
				Go to <a href="https://takeout.google.com/settings/takeout" target="_blank">takeout</a> on Google.
			</li>
			<li class="modal-li">
				Click on "DESELECT ALL" then only select "Location History" in JSON format.
			</li>
			<li class="modal-li">
				Click on "NEXT STEP".
			</li>
			<li class="modal-li">
				Choose tgz as the file type  and 1GB for the maximum file size. All is left now is to create your archive and download it by following the instructions.
			</li>
			<li class="modal-li">
				If your archive's size excess 1GB, it will be divided in several files. In this case, send us your 1GB file containing the most recent location history.
			</li>
		</ul>
	</div>
</ng-template>

<ng-template #modal_privacy let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">privacy policies</h4>
	  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	  </button>
	</div>
	<div class="modal-body">
		<p>The processing of your data will be automated and performed anonymously, data storage will be secure (the data are subject to access rights and are encrypted). By transferring your data, you accept and agree that INSA may collect, use and process your personal data for scientific purposes to correlate the history of your positions with the result of the personality test you have completed. Note that you can withdraw your consent at any time by contacting us by email at the following address antoine.boutet@insa-lyon.fr.</p>
	</div>
</ng-template>

<div class="container-fluid" style="padding-top:70px">
	<div class="row" >
		<div class="col-12">
			<div class="d-flex justify-content-center">
				<div class="col-6">
				<h1>Upload your mobility traces</h1>
						<p>Your mobility traces will be used to show you how much we can guess about yourself</p>
						<form [formGroup]="myForm" (ngSubmit)="submit()">
						<div class="form-group">
							<label for="file">File</label>
							<input 
								formControlName="file"
								id="file" 
								type="file" 
								class="form-control"
								(change)="onFileChange($event)">
							<div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
								<div *ngIf="f.file.errors.required">File is required.</div>
							</div>
						</div>
						<div class="form-group">
							<label for="email">email</label>
							<input formControlName="email" type="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email">
							<div *ngIf="f.email.errors &&(f.email.touched || f.email.dirty)" class="alert alert-danger">
								<div *ngIf="f.email.errors.required">
									Email is required.
								</div>  
								<div *ngIf="f.email.errors.email">
									Please enter a valid email.
								</div>
							</div>
						</div>
						<div class="d-flex justify-content-start">
						<div class="p-2"><button class="btn btn-primary" type="submit">Upload</button></div> 
						<div class="p-2"><a class="text-decoration-none align-text-top" (click)="open(modal_download)">How can I get my mobility traces?</a></div> 
						<div class="p-2"><a class="text-decoration-none align-text-top" (click)="open(modal_privacy)">Privacy policies</a></div>
						</div>
						</form>
						<br>
						
						<p>Once the upload is done, you will be redirected to a page to visualize the inferred data. The processing process can takes few minutes.</p>
						<ng-container *ngIf="error then errorBlock"></ng-container>
            <ng-template #errorBlock>
              <p style="color:red">An error occured during the upload or you have already uploaded your data, please try again later or <a [routerLink]="['/login']">Log-in</a>.</p>
            </ng-template>
            <ng-container *ngIf="demo then demoBlock else exampleBlock"></ng-container>
            <ng-template #exampleBlock>
                <em class='light'>Or you can</em>
                    <h3>Analyze preprocessed examples</h3>
                    <ul class="list">
                        <li><a class="example-button" (click)="loadExample(1)">
                            example #1</a></li>
                        <li><a class="example-button" (click)="loadExample(2)">
                            example #2</a></li>
                        <li><a class="example-button" (click)="loadExample(3)">
                            example #3</a></li>
                    </ul>
            </ng-template>
            <ng-template #demoBlock>
                <h3>available users: </h3>
                <ul>
                <ng-container *ngFor="let user of userlist">
                    <li><a class="example-button" (click)="loadDemoExample(user.username)">
                            {{user.username}}
                    </a></li>  
                </ng-container>
                </ul>
            </ng-template>
				</div>
			</div>
		</div>
	</div>
</div>




import { Component, OnInit, Input, OnDestroy, forwardRef, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, FormBuilder, FormControl, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface YesNoQuestionValues {
  value: boolean;
}

@Component({
  selector: 'question-yesno',
  templateUrl: './yesno.component.html',
  styleUrls: ['./yesno.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YesnoComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => YesnoComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YesnoComponent implements ControlValueAccessor, OnDestroy, OnInit{
  
  @Input() name: string;
  @Input() dirty : boolean = false;
  @Output() collapser = new EventEmitter<boolean>();
  form: FormGroup;
  subscriptions: Subscription[] = [];
  
  get value(): YesNoQuestionValues {
    return this.form.value;
  }

  set value(value: YesNoQuestionValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }
  
  get f() {
    return this.form.controls;
  }

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      question : new FormControl('', [Validators.required])
    });
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { question: { valid: false, }, };
  }

  reset() {
    this.form.reset();
  }
  
  /** ControlValueAccessor */
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /* ControlValueAccessor **/

  collapse(value : boolean) {
    this.collapser.emit(value);
  }

}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';


@Component({
  selector: 'app-job-chart',
  templateUrl: './job-chart.component.html',
  styleUrls: ['./job-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobChartComponent implements OnInit {
  @Input() chartData : ChartDataSets[];
  @Input() chartLabel : Label[]

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;


  constructor() { }

  ngOnInit(): void {
  }

}

<aol-map height="500px" #map>
    <aol-view [zoom]="15">
        <aol-coordinate [x]="lng" [y]="lat" [srid]="'EPSG:4326'"></aol-coordinate>
    </aol-view>
    <aol-layer-tile>
        <aol-source-osm></aol-source-osm>
    </aol-layer-tile>
    <aol-layer-vector [opacity]="1">
        <aol-source-vector #source>
            <aol-feature>
                <aol-geometry-point>
                        <aol-coordinate [x]="lng" [y]="lat" [srid]="'EPSG:4326'"></aol-coordinate>
                </aol-geometry-point>
                <aol-style>
                    <aol-style-circle [radius]="7">
                        <aol-style-fill [color]="'red'"></aol-style-fill>
                    </aol-style-circle>
                </aol-style>
            </aol-feature>
        </aol-source-vector>
    </aol-layer-vector>
</aol-map>
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment'
import { Router } from '@angular/router';
import { UserService } from './../../core/services/user/user.service';
import { ChartDataSets } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { ProfileService } from './../../core/services/profile/profile.service';
import { Profile } from './../../core/model/profile/profile';
import { Poi } from './../../core/model/trace/poi';
import { Component, OnInit } from '@angular/core';
import { faHome } from "@fortawesome/free-solid-svg-icons"
import { faBriefcase } from "@fortawesome/free-solid-svg-icons"


@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  faHome = faHome;
  faBriefcase = faBriefcase;
  profile : Profile;
  obfprofile : Profile;
  epsilon : number = 0.05;
  possibleEpsilon : number[] = [0.1, 0.05, 0.01];
  constructor(private router: Router, private profileService : ProfileService, private userService : UserService) { }
  //Genre
  genreData : MultiDataSet;
  genreLabel : Label[];
  personalityData : ChartDataSets[] = [];
  obfgenreLabel : Label[];
  obfgenreData : MultiDataSet;
  obfpersonalityData : ChartDataSets[] = [];
  pois : Poi[] = []

  ngOnInit(): void {
    this.userService.testToken().subscribe((res)=>{
      console.log(res);
      if(res){
        this.getProfile();
      }else{
        this.router.navigate(["/login"]);
      }
    });
  }
  getProfile() {
    this.profileService.getProfile().subscribe(profile =>{
      this.profile = profile;
      this.generateData();
      if(environment.demo){
        this.profileService.getAllPois().subscribe(
                    pois =>{
                        this.pois = pois;
                    }
                )
      }
    })
    this.getObfProfile();
    
  }

  getObfProfile(){
    this.profileService.getObfProfile(this.epsilon).subscribe(profile =>{
      this.obfprofile = profile;
      this.generateObfData();
    })
  }

  changeEpsilon(event) : void {
    this.epsilon = event.value;
    this.getObfProfile();
  }


  generateObfData() : void {
    console.log(this.obfprofile);
    if(this.obfprofile){
      //Genre
      if(this.obfprofile.genre){
        this.obfgenreData =[[this.obfprofile.genre.probaF, this.obfprofile.genre.probaM ]]
        this.obfgenreLabel = [['F'], ['M']]
      }
      if(this.obfprofile.personality){
        const perso = this.obfprofile.personality
        this.obfpersonalityData = [{data : [perso.openness, perso.consciousness, perso.extraversion, perso.agreability, perso.neuroticism], label: 'Big 5 inferences'}];
      }
    }

  }

  generateData() {
    console.log(this.profile);
    if(this.profile){
      //Genre
      if(this.profile.genre){
        this.genreData =[[this.profile.genre.probaF, this.profile.genre.probaM ]]
        this.genreLabel = [['F'], ['M']]
      }
      if(this.profile.personality){
        const perso = this.profile.personality
        this.personalityData = [{data : [perso.openness, perso.consciousness, perso.extraversion, perso.agreability, perso.neuroticism], label: 'Big 5 inferences'}];
      }
    }
  }

  isDemo() : boolean{
        return environment.demo;
    }
}

import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'pagination'
})
export class PaginationPipe implements PipeTransform {
    transform(array: Array<any>, currentPage:number, nbPerPage: number) {
        const start = (currentPage-1)*nbPerPage;
        return array.slice(start, start + nbPerPage);

    }
}
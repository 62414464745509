import { UserService } from './../../core/services/user/user.service';
import { Component, OnInit } from '@angular/core';
//import { RouterModule } from '@angular/router';
//import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
//import { DropdownDirective } from "src/app/shared/directives/dropdown.directive";
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { DropdownDirective } from "src/app/shared/directives/dropdown.directive";
import { environment } from '../../../environments/environment'
import { Location } from '@angular/common';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { User } from 'src/app/core/model/auth/user';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

  public currentUser: any;
  public decodedCurrentUser: any;
  public isAdmin: boolean;
  public currentUserId: number;
  public buildDate = environment.timeStamp
  userlist : User[] = [];
  
  constructor(
          private location: Location,
          private router: Router,
          private userService : UserService) {
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.currentUser = false;
    if(environment.demo){
        this.userService.getUserlist().subscribe((ul) => {
            this.userlist = ul
        });
    }

  }
    
  pageRefresh() {
    location.reload();
  }

  isAuth(): boolean{
    return this.userService.isAuth()
  }

  isDemo() : boolean{
        return environment.demo;
    }

  chooseUser(evt){
    console.log(evt.target.value)
    this.userService.setUsername(evt.target.value);
    this.router.navigate(["/trace"])
    }
}



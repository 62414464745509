import { UserService } from './../../core/services/user/user.service';
import { SurveyService } from './../../core/services/survey/survey.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

  form : FormGroup;
  public isCollapsedDecline: boolean;
  public isCollapsedScript : boolean;
  public isCollapsedInterview : boolean;
  public dirty = false;
  public done = false;

  constructor(private formBuilder : FormBuilder,private surveyService : SurveyService, private userService: UserService) { 
    this.form = this.formBuilder.group({
      age : [],
      nationality : [],
      genre : [],
      email : [],
      freq: [], 
      connected: [], 
      usages: [], 
      decline: [], 
      perso: [], 
      openness: [], 
      consciousness: [], 
      extraversion: [], 
      agreability: [], 
      neuroticism: [], 
      home: [], 
      work: [], 
      stats: [], 
      activities: [], 
      habits: [], 
      arrivaltime: [], 
      geolocinfer: [], 
      dataprocess: [], 
      dataprocessperso: [], 
      changehabits: [], 
      scriptblock: [], 
      scriptblockwhy: [], 
      protection: [], 
      protectionfinegrain: [], 
      interview: [],
      declinewhy : []
    });
  }

  get f(){
    return this.form.controls;
  }

  ngOnInit(): void {
    this.isCollapsedDecline = true;
    this.isCollapsedScript = true;
    this.isCollapsedInterview = true;
    this.userService.isSurveyDone().subscribe((r) => {
      console.log(r);
      this.done = r;
    })
  }
  
  submit() : void {
    this.dirty = true;
    var formdata = {} 
    console.log(this.form.valid)
    if(this.form.valid){
      Object.keys(this.form.value).map(key => {
        if(this.form.get(key).value){
          if(key == "nationality"){
            formdata[key] = this.form.get(key).value.question.alpha3Code
          }else{
            formdata[key] = this.form.get(key).value.question
          }
        }
      })
      console.log(formdata)
      this.surveyService.saveSurvey(formdata).subscribe((ok) => {
        this.done = true;
      })
    }
  }

  collapserDecline(value : boolean) {
    this.isCollapsedDecline = value;
  }
  collapserScript(value : boolean) {
    this.isCollapsedScript = value;
  }
  collapserInterview(value : boolean) {
    this.isCollapsedInterview = value;
  }
}

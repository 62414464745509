//
//import { NgModule } from '@angular/core';
//import { FormsModule } from '@angular/forms';
//import { BrowserModule } from '@angular/platform-browser';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { ReactiveFormsModule } from '@angular/forms';
//
//import { DatepickerComponent } from './datepicker.component';
//import { NgbDateStruct, NgbCalendar, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
//
//
//@NgModule({
//  imports: [BrowserModule, NgbModule, ReactiveFormsModule],
//  declarations: [DatepickerComponent],
//  exports: [DatepickerComponent],
//  bootstrap: []
//})
//export class DatepickerModule {}


import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgbdDatepickerPopup } from './datepicker-popup';

@NgModule({
  imports: [BrowserModule, FormsModule, NgbModule],
  declarations: [NgbdDatepickerPopup],
  exports: [NgbdDatepickerPopup],
  bootstrap: [NgbdDatepickerPopup]
})
export class NgbdDatepickerPopupModule {}
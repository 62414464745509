
export class TraceDay{
    uid : String;
    year: number;
    month: number;
    day: number;
    nb_pos: number;

    constructor(item: TraceDay) {
      Object.assign(this, item);
  }

    isDate(date: Date) : boolean {
      if(date.getUTCFullYear() == this.year && date.getUTCMonth() == this.month-1 && date.getUTCDate() == this.day-1){
        return true;
      }
      return false;
    }
}

import { AccountCreationResponse } from './../../core/model/auth/accountCreationResponse';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UserService } from './../../core/services/user/user.service';
import { Observable } from 'rxjs';
import { Location, formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/model/auth/user';


@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {
  closeResult = '';
  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email])
  });
  error = false;
  userlist : User[] = [];
  demo = environment.demo;

  constructor(private userService: UserService, private modalService: NgbModal, private http: HttpClient, private router: Router) { }

  get f() {
    return this.myForm.controls;
  }
  ngOnInit(): void {
    if(environment.demo){
        this.userService.getUserlist().subscribe((ul) => {
            this.userlist = ul
        });
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onFileChange(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  submit() {
    const formData = new FormData();
    formData.append('file', this.myForm.get('fileSource').value);
    formData.append('email', this.myForm.get('email').value);
    this.http.post<AccountCreationResponse>(environment.uploadUrl, formData)
      .pipe(
        tap(
          data => this.processLogin(data),
          error => this.processError(error)
        )
      )
      .subscribe((res) => {

      });
  }
  processError(error: any): void {
    this.error = true;
  }
  processLogin(data: AccountCreationResponse) {
    this.userService.generateToken(data.username, data.password);
    this.router.navigate(["/about"]);
  }

  loadDemoExample(usr : string){
    this.userService.setUsername(usr);
    this.router.navigate(["/trace"])
  }
  
  loadExample(ex: number) {
    var username : string;
    var pass : string;
    switch (ex) {
      case 1: {
        username = "68d2e8e20c4a5342c1e51f907d9c733e205528f3e7fdbd85f9358c8d07da3335";
        pass = "3ee4a899679ebf8d19fbab7f227bd10c";
        break;
      }
      case 2: {
        username = "043e9208064ccbfc31a7f9e75288d4ea70fc932a11195a2e03ce6ad59bf52426";
        pass = "3ee4a899679ebf8d19fbab7f227bd10c";

        break;
      }
      case 3: {
        username = "8dd1c81993f9e59697253d551e85feaaf5e033be38250542a8870f7b481a74f5";
        pass = "3ee4a899679ebf8d19fbab7f227bd10c";
        break;
      }
      default: {
        console.log("hello: " + ex);
        username = "68d2e8e20c4a5342c1e51f907d9c733e205528f3e7fdbd85f9358c8d07da3335";
        pass = "3ee4a899679ebf8d19fbab7f227bd10c";
        break;
      }
    }
    this.userService.generateToken(username, pass).subscribe((res) => {
      this.userService.clearPassword();
      this.router.navigate(["/trace"]);
    });

  }
}

<aol-map (onClick)="mapOnClick($event)">
    <aol-interaction-default></aol-interaction-default>
    <aol-view [zoom]="zoomLevel">
        <aol-coordinate [x]="centerX" [y]="centerY" [srid]="'EPSG:4326'"></aol-coordinate>
    </aol-view>
    <aol-layer-tile>
        <aol-source-osm></aol-source-osm>
    </aol-layer-tile>
    <aol-layer-vector [opacity]="1">
        <aol-source-vector #source>
            <ng-container *ngFor="let pos of positions; let i = index">
                <ng-container *ngIf="pos.acc < 150 && pos.inpoi">
                    <aol-feature [id]="i">
                        <aol-geometry-point>
                            <aol-coordinate [x]="pos.poi.lng" [y]="pos.poi.lat" [srid]="'EPSG:4326'"></aol-coordinate>
                        </aol-geometry-point>
                        <aol-style>
                            <aol-style-icon [src]="'./assets/img/Map_marker_red.png'" [anchor]="[0.5, 1]" [anchorXUnits]="'fraction'" [anchorYUnits]="'fraction'" [scale]="0.05"></aol-style-icon>
                        </aol-style>
                    </aol-feature>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="positions">
                <aol-feature>
                    <aol-geometry-linestring>
                        <aol-collection-coordinates [coordinates]="posArray" [srid]="'EPSG:4326'">
                        </aol-collection-coordinates>
                    </aol-geometry-linestring>
                    <aol-style>
                        <aol-style-stroke [color]="'red'" [width]="2" [lineJoin]="'bevel'"></aol-style-stroke>
                    </aol-style>
                </aol-feature>
            </ng-container>
            <ng-container *ngIf="obfuscation">
                <ng-container *ngIf="obfpositions">
                    <aol-feature>
                        <aol-geometry-linestring>
                            <aol-collection-coordinates [coordinates]="obfposArray" [srid]="'EPSG:4326'">
                            </aol-collection-coordinates>
                        </aol-geometry-linestring>
                        <aol-style>
                            <aol-style-stroke [color]="'black'" [width]="2" [lineJoin]="'bevel'"></aol-style-stroke>
                        </aol-style>
                    </aol-feature>
                </ng-container>
                <ng-container *ngFor="let pos of obfpositions; let i = index">
                    <ng-container *ngIf="pos.acc < 150 && pos.inpoi">
                        <aol-feature [id]="i+positions.length">
                            <aol-geometry-point>
                                <aol-coordinate [x]="pos.poi.lng" [y]="pos.poi.lat" [srid]="'EPSG:4326'"></aol-coordinate>
                            </aol-geometry-point>
                            <aol-style>
                                <aol-style-icon [src]="'./assets/img/Map_marker_black.png'" [anchor]="[0.5, 1]" [anchorXUnits]="'fraction'" [anchorYUnits]="'fraction'" [scale]="0.05"></aol-style-icon>
                            </aol-style>
                        </aol-feature>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container>
                <aol-feature>
                    <aol-geometry-point>
                        <aol-coordinate [x]="(obsCurrentPosition | async)?.lng" [y]="(obsCurrentPosition | async)?.lat" [srid]="'EPSG:4326'"></aol-coordinate>
                    </aol-geometry-point>
                    <aol-style>
                        <aol-style-circle [radius]="8">
                            <aol-style-fill [color]="'blue'"></aol-style-fill>
                        </aol-style-circle>
                    </aol-style>
                </aol-feature>
            </ng-container>
            <ng-container *ngIf="elementPos">
                <aol-feature>
                    <aol-geometry-point>
                        <aol-coordinate [x]="elementPos.lng" [y]="elementPos.lat" [srid]="'EPSG:4326'"></aol-coordinate>
                    </aol-geometry-point>
                    <aol-style>
                        <aol-style-circle [radius]="5">
                            <aol-style-fill [color]="'green'"></aol-style-fill>
                        </aol-style-circle>
                    </aol-style>
                </aol-feature>
            </ng-container>
        </aol-source-vector>
    </aol-layer-vector>
</aol-map>

<!-- Calendar -->
<div id="datepicker-large" class="col-6 col-sm-5 col-md-3 col-xl-2" style="position: fixed; top: 75px; left: 0px; background: white;" [@collapse-left]="calendarState">
    <div (click)="changeCalendarState()">
        <h3 style="position: fixed; top: 7px">Calendar</h3>
        <fa-icon [icon]="faCalendarIcon" class="fa-2x" style="color: #007bff;padding-left:96%"></fa-icon>
    </div>
    <div style="width: 88%; margin-left:6%;margin-right: 6%;max-height: 85vh;overflow-y:auto; overflow-x:hidden;">
        <mat-calendar [dateFilter]="dateFilter" [startView]="startView() | async" [startAt]="startDate() | async" (selectedChange)="selectDate($event)"></mat-calendar>
        <hr/>
        <span><b>Granularity : </b></span>
        <ngx-slider [(value)]="granularity" [options]="options" (valueChange)=" changeGranularity($event)"></ngx-slider>
        <!--
        <ul>
          <ng-container *ngFor="let gran of possibleGranularity">
            <li>
              <a (click)="changeGranularity(gran)">
                <ng-container *ngIf="gran == granularity"> > </ng-container>
                {{gran}}
              </a>
            </li>
          </ng-container>
        </ul>
        -->
        <hr/>
        <span><b>Timeline</b></span><br>
        <small>the blue dot shows your position at the selected time</small><br>
        <ng-container *ngIf="isDateSelected"><span>Time : {{currentTime | date:'HH:mm:ss'}}</span></ng-container>
        <mat-slider [disabled]="!isDateSelected" [min]="0" [max]="maxSliderTime()" (input)="changeCurrentTime($event)"></mat-slider>
        <hr>
        <span><b>Obfuscation</b></span><br>
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" (click)="toggleObf()">
            <label class="form-check-label" for="flexSwitchCheckDefault">Show Obfuscated traces</label>
        </div>
        <div class="row">
            <label class="col-6">Epsilon</label>
            <mat-select [(value)]="epsilon" class="col-6" (selectionChange)="changeEpsilon($event)">
                <ng-container *ngFor="let epsi of possibleEpsilon">
                    <mat-option [value]="epsi">{{epsi}}</mat-option>
                </ng-container>
            </mat-select>
        </div>
    </div>
</div>

<!-- Poi details -->
<div class="col-10 col-sm-10 col-md-3 col-xl-3" style="position: fixed; top: 100px; right: 20px; background: white;padding-left:20px;" [@collapse-right]="poiState">
    <div (click)="changePoiState()">
        <fa-icon [icon]="faPoiIcon" class="fa-2x" style="color: #007bff;margin-left:-15px;"></fa-icon>
        <h3 style="position: fixed; top: 7px;padding-left: 15px;">Détails du POI </h3>
    </div>
    <div style="max-height: 70vh;overflow-y:auto">
        <app-poi-detail [poi]="selectedPoi" [granularity]="granularity" (latlngChanged)="changeElementPos($event)"></app-poi-detail>
    </div>
</div>
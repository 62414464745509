<div (click)="collapse.toggle(); updateMap();">
    <h4>
        <ng-container *ngIf="isCollapsed then collapsedIcon else fullIcon"></ng-container> {{place.start | date:'dd/MM/yyyy' }} - {{place.end | date:'dd/MM/yyyy' }}</h4>
    <ng-template #collapsedIcon>
        <fa-icon [icon]="faCollapsedIcon" class="fa" style="color: #007bff;"></fa-icon>
    </ng-template>
    <ng-template #fullIcon>
        <fa-icon [icon]="faFullIcon" class="fa" style="color: #007bff;"></fa-icon>
    </ng-template>
</div>

<div class="row" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div class="col-12 col-lg-6">
        <app-poi-detail [poi]="place.poi"></app-poi-detail>
    </div>
    <div class="col-12 col-lg-6">
        <app-static-map [lat]="place.poi.lat" [lng]="place.poi.lng" #staticmap></app-static-map>
    </div>
</div>
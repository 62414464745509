import { Router } from '@angular/router';
import { UserService } from './../../core/services/user/user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.css']
})
export class WaitingComponent implements OnInit {

  username : string;
  password: string;
  processed: boolean = false;
  constructor(private userService : UserService, private router: Router) { }

  ngOnInit(): void {
    this.username = this.userService.getUsername();
    this.password = this.userService.getPassword();
    this.checkProcessing();
  }

  checkProcessing() {
    this.userService.isProcessed().subscribe((ok) => {
      this.processed = ok;
      if (!ok) {
        setTimeout(() => {
          this.checkProcessing();
        }, 30000); // == 30s 
      }
    })
  }

}

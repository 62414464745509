<div class="container">
    <ng-container *ngIf="done then SurveyDone else SurveyTodo"></ng-container>
    <ng-template #SurveyDone>
        <div class="row">
            <div class="col-12" style="text-align: center;padding-top: 100px; padding-bottom: 100px; background: #FFFFFF;">
                <h4>Merci d'avoir rempli ce questionnaire.</h4>
            </div>
        </div>
    </ng-template>
    <ng-template #SurveyTodo>
        <div class="row">
            <div class="col-12" style="padding-top: 100px; padding-bottom: 100px; background: #FFFFFF;">
                <div class="col-10 offset-1">
                <h4>Afin de recueillir vos perceptions et ressentiments sur les inférences d'informations personnelles pouvant être réalisées à partir de vos données de mobilité, Merci de répondre à ce questionnaire :</h4>
                    <!-- ASL -->
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <question-number [dirty]="dirty" [name]="'age'" formControlName="age">Age</question-number>
                        <question-choice [dirty]="dirty" [name]="'genre'" formControlName="genre"
                            [choices]="[ 'femme','homme']">genre</question-choice>
                        <question-nationality [dirty]="dirty" [name]="'nationality'" formControlName="nationality">nationalité</question-nationality>
                        <question-choice [dirty]="dirty" [name]="'freq'" formControlName="freq" 
                        [choices]="['Une fois par semaine en moyenne', 
                                    'moins d\'une fois par jour', 
                                    'plusieurs fois par jour', 
                                    'au moins une fois par heure', 
                                    'au moins une fois toutes les 15 minutes']">
                                    1. A quelle fréquence vous connectez vous aux réseaux sociaux ou autres applications mobiles à partir de votre téléphone ?
                        </question-choice>
                        <question-yesno [dirty]="dirty" [name]="'connected'" formControlName="connected">
                            2. Vous considérez vous comme quelqu'un de connecté(e) ?
                        </question-yesno>
                        <question-choice [dirty]="dirty" [name]="'usages'" formControlName="usages" 
                            [choices]="['jamais','rarement','de temps en temps','régulièrement','très régulièrement']">
                        3. Utilisez vous des services ou applications mobiles géolocalisés, c'est à dire utilisant votre position (exemple : une application comme Maps vous aidant à trouver un lieu) ?
                        </question-choice>
                        <question-yesno [dirty]="dirty" [name]="'decline'" formControlName="decline" (collapser)="collapserDecline($event)">
                            4. Avez vous déjà refusé de partager votre géolocalisation à une application le demandant ?
                        </question-yesno>
                        <div class="offset-1" #collapseDecline="ngbCollapse" [(ngbCollapse)]="isCollapsedDecline">
                            <question-text-area [dirty]="dirty" [name]="'declinewhy'" formControlName="declinewhy" [required]="false">
                                4.bis Pourquoi ? Vous estimiez que ce n'était pas nécessaire au fonctionnement de l'application ? Vous estimiez que c'était trop intrusif ? Autre ?
                            </question-text-area>
                        </div>
                        <question-yesno [dirty]="dirty" [name]="'perso'" formControlName="perso">
                            5. Pensez vous que vos traits de personnalité soient des informations confidentielles qui devraient rester privées ?
                        </question-yesno>
                        <hr>
                        <label>
                            6. Seriez vous prêt(e) à dévoiler uniquement certains traits de personnalité ?
                        </label>
                        <div class="offset-1">
                            <question-yesno [dirty]="dirty" [name]="'openness'" formControlName="openness">
                                Ouverture à l'expérience <small>(curiosité, imagination, esthétisme, intérêts variés, émotions, valeurs non conventionnelles)</small>
                            </question-yesno>
                            <question-yesno [dirty]="dirty" [name]="'consciousness'" formControlName="consciousness">
                                Conscienciosité <small>(efficience, organisation, rigueur, minutie, autodiscipline/paresse, délibération, impulsivité)</small>
                            </question-yesno>
                            <question-yesno [dirty]="dirty" [name]="'extraversion'" formControlName="extraversion">
                                Extraversion <small>(Sociabilité, affirmation de soi, énergique, aventure, enthousiasme)</small>
                            </question-yesno>
                            <question-yesno [dirty]="dirty" [name]="'agreability'" formControlName="agreability">
                                Agréabilité <small>(confiance, facilité de contact, altruistme, collaboration/obstination, modestie/prétention, empathie)</small>
                            </question-yesno>
                            <question-yesno [dirty]="dirty" [name]="'neuroticism'" formControlName="neuroticism">
                                Névrosisme <small>(anxiété, irritabilité, peu de satisfaction, timide, humeurs cahngeantes, peu de confiance en soi)</small>
                            </question-yesno>
                        </div>
                        <hr>
                        <label>
                            7. Seriez vous prêt(e) à dévoiler certaines informations personnelles à des applications mobiles ? Lesquelles ?
                        </label>
                        <div class="offset-1">
                            <question-yesno [dirty]="dirty" [name]="'home'" formControlName="home">
                                lieu d'habitation
                            </question-yesno>
                            <question-yesno [dirty]="dirty" [name]="'work'" formControlName="work">
                                lieu de travail
                            </question-yesno>
                            <question-yesno [dirty]="dirty" [name]="'stats'" formControlName="stats">
                                statistiques sur vos arrêts (adresse, duréee)
                            </question-yesno>
                            <question-yesno [dirty]="dirty" [name]="'activities'" formControlName="activities">
                                activités/hobbys
                            </question-yesno>
                            <question-yesno [dirty]="dirty" [name]="'habits'" formControlName="habits">
                                habitudes de transport
                            </question-yesno>
                            <question-yesno [dirty]="dirty" [name]="'arrivaltime'" formControlName="arrivaltime">
                                à quelle heure vous rentrez chez vous
                            </question-yesno>
                        </div>
                        <question-yesno [dirty]="dirty" [name]="'geolocinfer'" formControlName="geolocinfer">
                            8. Est ce que vous vous sentez embarrassé(e) par le fait qu'une application mobile utilisant la géolocalisation puisse apprendre des choses sur vous à partir des données de mobilité ?
                        </question-yesno>
                        <question-yesno [dirty]="dirty" [name]="'dataprocess'" formControlName="dataprocess">
                            9. Si les règles d'usage d'une application mobile mentionnaient un traitement de vos données pour prédire vos traits de personnalité, installeriez vous cette application ?
                        </question-yesno>
                        <question-yesno [dirty]="dirty" [name]="'dataprocessperso'" formControlName="dataprocessperso">
                            10. Si les règles d'usage d'une application mobile que vous utilisez mentionnaient un traitement de vos données pour exploiter vos traits de personnalité à des fins de personnalisation pour améliorer votre expérience, accepteriez vous cette exploitation ?
                        </question-yesno>
                        <question-yesno [dirty]="dirty" [name]="'changehabits'" formControlName="changehabits">
                            11. Changeriez vous vos habitudes en terme de parnamee de vos données géolocalisées avec des applications mobiles en sachant que vos traits de personnalité peuvent être prédits de vos données ?
                        </question-yesno>
                        <question-yesno [dirty]="dirty" [name]="'scriptblock'" formControlName="scriptblock" (collapser)="collapserScript($event)">
                            12. Utilisez vous des solutions pour bloquer les publicités sur votre navigateur Web sur votre ordinateur (Adblock ou Ghostery par exemple) ?
                        </question-yesno>
                        <div class="offset-1" #collapseScript="ngbCollapse" [(ngbCollapse)]="isCollapsedScript">
                            <question-text-area [dirty]="dirty" [name]="'scriptblockwhy'" formControlName="scriptblockwhy" [required]="false">
                                12.bis Dans quel but ? pour des raisons de vie privée ? Pour avoir une navigation plus fluide avec moins de publicités ? Autre ?
                            </question-text-area>
                        </div>
                        <question-yesno [dirty]="dirty" [name]="'protection'" formControlName="protection">
                            13. Seriez vous prêt(e) à installer et utiliser une application qui protège vos données personnelles contre certains apprentissages tel que la prédiction de vos traits de personnalité ?                      
                        </question-yesno>
                        <question-yesno [dirty]="dirty" [name]="'protectionfinegrain'" formControlName="protectionfinegrain">
                            14. Souhaiteriez vous pouvoir contrôler les informations qui peuvent être prédites ou apprises par l'exploitation de vos données personnelles (définir vous même le niveau de précision ou la valeur d'un attribut, tel qu'une dimension de votre personnalité
                            comme l'ouverture par exemple, qu'une application pourrait prédire à partir de vos données) ?
                        </question-yesno>
                        <question-yesno [dirty]="dirty" [name]="'interview'" formControlName="interview" (collapser)="collapserInterview($event)">
                            15. Accepteriez vous de répondre à un petit interview qui nous permettrait de mieux cerner vos ressentis et perceptions ?
                        </question-yesno>
                        <div class="offset-1" #collapseInterview="ngbCollapse" [(ngbCollapse)]="isCollapsedInterview">
                            <question-email formControlName="email" [required]="false"></question-email>
                        </div>
                        <div class="p-2"><button class="btn btn-primary" type="submit">Valider</button></div> 
                    </form>
                </div>
            </div>
        </div>
    </ng-template>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgbdProgressbarShowvalue } from './progressbar-showvalue';

@NgModule({
    imports: [BrowserModule, NgbModule, CommonModule],
    declarations: [NgbdProgressbarShowvalue],
    exports: [NgbdProgressbarShowvalue]
})
export class ProgressbarShowvalueModule { }

<ng-container *ngIf="name">
    <hr>
    <div class="form-group" [formGroup]="form">
        <label for="{{name}}" style="text-align: justify;"><ng-content></ng-content></label>
        <div class="row">
            <div class="offset-1">
                <ng-container *ngFor="let option of choices; let i = index">
                    <div class="row">
                        <div class="form-check form-check-inline">
                            <input formControlName="question" class="form-check-input" [attr.name]="name" type="radio" [attr.id]="i+'Check'+name" [value]="i">
                            <label class="form-check-label" [attr.for]="i+'Check'+name">{{option}}</label>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <ng-container *ngIf="dirty && f.question.invalid"><hr style="background-color: rgb(255, 89, 89);"></ng-container>
</ng-container>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './file-uploader.component';
import { FileUploadModule } from "ng2-file-upload";


@NgModule({
  imports: [
    CommonModule,
    FileUploadModule
  ],
  declarations: [FileUploaderComponent],
  exports:[FileUploaderComponent]
})
export class FileUploaderModule { }

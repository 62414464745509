<div style="margin-top:75px"></div>
<div class="container" style="background:#fafafa">
    <div class="d-flex p-2 justify-content-center" >
      <form [formGroup]="loginForm" (ngSubmit)="submit()">
      <div class="form-group">
          <label for="uid">UID</label>
          <input type="text" class="form-control" id="uid" formControlName="uid"  placeholder="uid">
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" class="form-control" id="password" formControlName="password" placeholder="Password">
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </div>
    <ng-container *ngIf="error">
      <div class="d-flex p-4 justify-content-center" >
        <div class="alert alert-danger" role="alert" style="margin-bottom: 10px;">
        Erreur : vérifier uid et password.
        </div>
      </div>
    </ng-container>
</div>


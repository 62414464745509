import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { MapComponent } from 'ngx-openlayers';

@Component({
  selector: 'app-static-map',
  templateUrl: './static-map.component.html',
  styleUrls: ['./static-map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticMapComponent implements OnInit {

  @ViewChild("map") map : MapComponent;
  @Input() lat : number;
  @Input() lng : number;

  constructor() { }

  ngOnInit(): void {
  }

  resize() : void {
    this.map.instance.updateSize();
  }

}

<div class="container">
    <div class="row">
        <div class="col-12 col-md-6" style="margin-top: 56px; background: #FFFFFF;">
            <h2>Raw Data</h2>
            <hr>
            <ng-container *ngIf="profile then profileLoadedBlock else loadingProfileBlock"></ng-container>
            <ng-template #profileLoadedBlock>
                <div id="genre">
                    <h3>Genre</h3>
                    <ng-container *ngIf="profile.genre then genreBlock else noDataBlock"></ng-container>
                    <ng-template #genreBlock>
                        <div>
                            <app-genre-chart [chartData]="genreData" [chartLabel]="genreLabel"></app-genre-chart>
                        </div>
                    </ng-template>
                </div>
                <hr>
                <div id="personality">
                    <h2>Personality</h2>
                    <ng-container *ngIf="profile.personality then persoBlock else noDataBlock"></ng-container>
                    <ng-template #persoBlock>
                        <div>
                            <personality-chart [data]="personalityData"></personality-chart>
                        </div>
                    </ng-template>
                </div>
                <hr>
                <div id="homes">
                    <h2>
                        <fa-icon [icon]="faHome" class="fa" style="color: #007bff;"></fa-icon> Home places</h2>
                    <ng-container *ngIf="profile.homes then homeBlock else noDataBlock"></ng-container>
                    <ng-template #homeBlock>
                        <ng-container *ngFor="let home of profile.homes">
                            <div>
                                <app-place-card [place]="home"></app-place-card>
                            </div>
                            <hr>
                        </ng-container>
                    </ng-template>
                </div>
                <div id="work">
                    <h2>
                        <fa-icon [icon]="faBriefcase" class="fa" style="color: #007bff;"></fa-icon> Work places</h2>
                    <ng-container *ngIf="profile.works then workBlock else noDataBlock"></ng-container>
                    <ng-template #workBlock>
                        <ng-container *ngFor="let work of profile.works">
                            <div>
                                <app-place-card [place]="work"></app-place-card>
                            </div>
                            <hr>
                        </ng-container>
                    </ng-template>
                </div>
            </ng-template>
            <ng-template #loadingProfileBlock>
                <p style="text-align: center;">Loading profile, please wait.</p>
            </ng-template>
        </div>
        <ng-container *ngIf="isDemo() then demoBlock else defaultBlock"></ng-container>
        <ng-template #demoBlock>
            <div class="col-12 col-md-6" style="margin-top: 56px">
                <div style=" background: #FFFFFF;" class="col-12">
                    <ng-container *ngFor="let poi of pois">
                        <app-poi-card [poi]="poi"></app-poi-card>
                            <hr>
                    </ng-container>
                </div>
            </div>
        </ng-template>
        <ng-template #defaultBlock>
            <div class="col-12 col-md-6" style="margin-top: 56px">
                <div style=" background: #FFFFFF;" class="col-12">
                    <h2>Obfuscated Data <small>(Epsilon : 
            <mat-select [(value)]="epsilon" class="col-3" (selectionChange)="changeEpsilon($event)">
            <ng-container *ngFor="let epsi of possibleEpsilon">
                <mat-option [value]="epsi">{{epsi}}</mat-option>
            </ng-container>
            </mat-select>)</small></h2>

                    <hr>
                    <ng-container *ngIf="obfprofile then obfprofileLoadedBlock else loadingProfileBlock"></ng-container>
                    <ng-template #obfprofileLoadedBlock>
                        <div id="genre">
                            <h3>Genre</h3>
                            <ng-container *ngIf="obfprofile.genre then obfgenreBlock else noDataBlock"></ng-container>
                            <ng-template #obfgenreBlock>
                                <div>
                                    <app-genre-chart [chartData]="obfgenreData" [chartLabel]="obfgenreLabel"></app-genre-chart>
                                </div>
                            </ng-template>
                        </div>
                        <hr>
                        <div id="personality">
                            <h2>Personality</h2>
                            <ng-container *ngIf="obfprofile.personality then obfpersoBlock else noDataBlock"></ng-container>
                            <ng-template #obfpersoBlock>
                                <div>
                                    <personality-chart [data]="obfpersonalityData"></personality-chart>
                                </div>
                            </ng-template>
                        </div>
                        <hr>
                        <div id="homes">
                            <h2>
                                <fa-icon [icon]="faHome" class="fa" style="color: #007bff;"></fa-icon> Home places</h2>
                            <ng-container *ngIf="obfprofile.homes then obfhomeBlock else noDataBlock"></ng-container>
                            <ng-template #obfhomeBlock>
                                <ng-container *ngFor="let home of obfprofile.homes">
                                    <div>
                                        <app-place-card [place]="home"></app-place-card>
                                    </div>
                                    <hr>
                                </ng-container>
                            </ng-template>
                        </div>
                        <div id="work">
                            <h2>
                                <fa-icon [icon]="faBriefcase" class="fa" style="color: #007bff;"></fa-icon> Work places</h2>
                            <ng-container *ngIf="obfprofile.works then obfworkBlock else noDataBlock"></ng-container>
                            <ng-template #obfworkBlock>
                                <ng-container *ngFor="let work of obfprofile.works">
                                    <div>
                                        <app-place-card [place]="work"></app-place-card>
                                    </div>
                                    <hr>
                                </ng-container>
                            </ng-template>
                        </div>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<ng-template #noDataBlock>
    <p style="text-align: center;">No prediction available.</p>
</ng-template>g

import { Router } from '@angular/router';
import { UserService } from './../../core/services/user/user.service';
import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    uid : new FormControl('', [Validators.required]),
    password : new FormControl('', [Validators.required])
  });
  error : boolean = false;
  constructor(private userService : UserService, private router: Router) { }

  ngOnInit(): void {
  }

  submit(){
    console.log("here");
    this.userService.generateToken(this.loginForm.get("uid").value,this.loginForm.get("password").value).subscribe((res) =>{
        if(res){
          this.router.navigate(["/trace"])
        }else{
          this.error = true;
        }
    });
  }
}

import { StaticMapComponent } from './../../../shared/static-map/static-map.component';
import { Place } from './../../../core/model/profile/place';
import { Poi } from './../../../core/model/trace/poi';
import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-poi-card',
  templateUrl: './poi-card.component.html',
  styleUrls: ['./poi-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoiCardComponent implements OnInit {

  @ViewChild("staticmap") map : StaticMapComponent;
  @Input() poi : Poi;
  public faCollapsedIcon = faAngleRight;
  public faFullIcon = faAngleDown;
  public isCollapsed;

  constructor() { }

  ngOnInit(): void {
    this.isCollapsed = true;
  }

  updateMap() : void {
    this.map.resize();
  }

}

<ng-container *ngIf="name">
    <hr>
    <div class="form-group" [formGroup]="form">
        <label for="{{name}}" style="text-align: justify;"><ng-content></ng-content></label>
        <div class="row">
            <div class="offset-1">
                <div class="form-check form-check-inline">
                    <input formControlName="question" class="form-check-input" [attr.name]="name" type="radio" [attr.id]="'yesCheck'+name" value=true (click)="collapse(false)">
                    <label class="form-check-label" [attr.for]="'yesCheck'+name">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                    <input formControlName="question" class="form-check-input" [attr.name]="name" type="radio" [attr.id]="'noCheck'+name" value=false (click)="collapse(true)">
                    <label class="form-check-label" [attr.for]="'noCheck'+name">No</label>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="dirty && f.question.invalid"><hr style="background-color: rgb(255, 89, 89);"></ng-container>
</ng-container>
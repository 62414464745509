import { Component, OnInit, Input } from '@angular/core';
import { ViewEncapsulation } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
//  styleUrls: ['./modal.component.css']
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .dark-modal .modal-content {
      background-color: #292b2c;
      color: white;
    }
    .dark-modal .close {
      color: white;
    }
    .light-blue-backdrop {
      background-color: #5cb3fd;
    }
  `]
})
export class ModalComponent  {

    @Input() modalTitle: string;
    closeResult: string ;
    @Input() confirmationMessage : string
    @Input() contentMessage: string;
//    @Output() outSearch: EventEmitter<boolean> = new EventEmitter()

    
    constructor(public modal: NgbActiveModal) {
        this.closeResult= "toto";
    }
    
    closeModal() {
        this.modal.close('Modal Closed');
    }

//    names: Array<string> = ['ana', 'jim', 'ben']
//    // names: string[] =
//
//    search(ev: Event) {
//        this.outSearch.emit(this.name)
//    }
}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
//import { FileUploader } from 'ng2-file-upload';
import { FileUploadModule } from 'ng2-file-upload';
import { FileSelectDirective, FileDropDirective } from 'ng2-file-upload';
import { FileUploader } from 'ng2-file-upload';
import { ConstService } from "src/app/core/services/const.service";

//const URL = './';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})

export class FileUploaderComponent {
    
  private URL = this.constService.baseUrl; 
    
  constructor(private constService: ConstService) { }
  
  public uploader: FileUploader = new FileUploader({url: this.URL, itemAlias: 'photo'});

  ngOnInit() {
    this.uploader.onAfterAddingFile = (file) => { 
        file.withCredentials = false; 
        console.log('ImageUpload:onAfterAddingFile:', file);

        };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
         console.log('ImageUpload:uploaded:', item, status, response);
         alert('File uploaded successfully');
     };
 }
}

// ********************http://roufid.com/angular-file-upload-spring-boot/
//  @ViewChild('fileInput') fileInput: ElementRef;
//  
//  uploader: FileUploader;
//  isDropOver: boolean;
// 
//  ngOnInit(): void {
//    const headers = [{name: 'Accept', value: 'application/json'}];
//    this.uploader = new FileUploader({url: 'api/files', autoUpload: true, headers: headers});
// 
//    this.uploader.onCompleteAll = () => alert('File uploaded');
//  }
// 
//  fileOverAnother(e: any): void {
//    this.isDropOver = e;
//  }
// 
//  fileClicked() {
//    this.fileInput.nativeElement.click();
//  }
//********************http://roufid.com/angular-file-upload-spring-boot/


//https://github.com/valor-software/ng2-file-upload/blob/master/demo/components/file-upload/simple-demo.ts
//import {Component} from '@angular/core';
//import {CORE_DIRECTIVES, FORM_DIRECTIVES, NgClass, NgStyle} from '@angular/common';
//import {FILE_UPLOAD_DIRECTIVES, FileUploader} from '../../../ng2-file-upload';
//
//
//@Component({
//  selector: 'file-uploader',
//  templateUrl: './file-uploader.component.html',
//  styleUrls: ['./file-uploader.component.css'],
//  directives: [FILE_UPLOAD_DIRECTIVES, NgClass, NgStyle, CORE_DIRECTIVES, FORM_DIRECTIVES]
//})
//export class SimpleDemoComponent {
//  public uploader:FileUploader = new FileUploader({url: URL});
//  public hasBaseDropZoneOver:boolean = false;
//  public hasAnotherDropZoneOver:boolean = false;
//
//  public fileOverBase(e:any):void {
//    this.hasBaseDropZoneOver = e;
//  }
//
//  public fileOverAnother(e:any):void {
//    this.hasAnotherDropZoneOver = e;
//  }
//}
//
//https://github.com/valor-software/ng2-file-upload/blob/master/demo/components/file-upload/simple-demo.ts
